import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CountryDataState } from "../../models/models";

const initialState:CountryDataState = {
  CountryDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    countryDataRequest: (state) => {
        state.isLoading = true;
       
      },
      getCountryDataSuccess: (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.CountryDetails = action.payload;
      },
      getCountryDataFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      }
  },
});

export const {
    countryDataRequest,
    getCountryDataFailure,
    getCountryDataSuccess,
  } = countrySlice.actions;
  
  export default countrySlice.reducer;
  