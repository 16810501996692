import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BookingWidget,
  AirportData,
  AirportModalVisible,
  BookingFieldData,
  MultiCityBookingFieldData,
} from "../models/models";
import { BookFlightAirportDropdown } from "../../../utils/enum-helper";

export interface bookingData {
  data: null | BookingWidget;
  airportData: null | AirportData;
  fieldData: null | BookingFieldData;
  mobileModalVisible: AirportModalVisible[] | null;
  selectedRecentSearchData: any | null;
  selectedRecentSearchDataMobile: any | null;
  fromReset: boolean;
  dropdownOption: any | null;
  isLoading: Boolean;
  error: Boolean;
  tabIndex: Array<any>;
}

const location_country = window.location.href.split("/")[3];
const location = location_country.split("-")[1];

const getDateFormat = (date: any, type?: string) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (type) {
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

const initialState: bookingData = {
  data: null,
  airportData: null,
  fieldData: null,
  mobileModalVisible: [
    { visible: false, label: "from", airportDetails: null },
    { visible: false, label: "to", airportDetails: null },
  ],
  isLoading: false,
  selectedRecentSearchData: null,
  selectedRecentSearchDataMobile: null,
  dropdownOption: {},
  error: false,
  fromReset: false,
  tabIndex: [],
};

const bookingWidgetSlice = createSlice({
  name: "bookingWidget",
  initialState,
  reducers: {
    airportDataRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
    },
    getAirportDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.airportData = action.payload;
      state.isLoading = false;
    },
    getAirportDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    updateFieldData: (state: any, action: PayloadAction<any>) => {
      const { fieldLabel, newValue } = action.payload;
      if (state.fieldData === null) {
        state.fieldData = {
          from: "",
          to: "",
          departReturn: [],
          depart: "",
          passengers: {},
          class: "",
          promotionCode: "",
          bookingReferenceTicketNumber: "",
          bookingReferenceTicketNumberRopNumber: "",
          lastName: "",
          flightNumber: "",
          date: "",
        };
      }
      if (fieldLabel === "from") {
        state.fieldData[fieldLabel] = newValue;
      } else if (fieldLabel === "to") {
        state.fieldData[fieldLabel] = newValue;
      } else if (fieldLabel === "departReturn") {
        const formattedDates = newValue.map((date: any) => {
          if (date === null) return null;
          return getDateFormat(date);
        });
        state.fieldData[fieldLabel] = formattedDates;
        state.fieldData["depart"] = formattedDates[0];
      } else if (fieldLabel === "depart") {
        const formattedDate = getDateFormat(newValue[0]);
        state.fieldData[fieldLabel] = formattedDate;
        if (
          new Date(formattedDate) < new Date(state.fieldData["departReturn"][1])
        ) {
          state.fieldData["departReturn"][0] = formattedDate;
        } else {
          state.fieldData["departReturn"][0] = formattedDate;
          let nextDate = new Date(formattedDate);
          state.fieldData["departReturn"][1] = new Date(
            nextDate.setDate(nextDate.getDate() + 1)
          );
        }
      } else if (fieldLabel === "date") {
        const formattedDate = getDateFormat(newValue, "simplicity");
        state.fieldData[fieldLabel] = formattedDate;
      } else if (
        fieldLabel === "passengers" ||
        fieldLabel === "class" ||
        fieldLabel === "promotionCode"
      ) {
        state.fieldData[fieldLabel] = newValue;
      } else {
        state.fieldData[fieldLabel] = newValue.target.value;
      }
    },
    resetFieldData: (state: any) => {
      state.fieldData = {
        from: "",
        to: "",
        departReturn: [],
        depart: "",
        passengers: {},
        class: "",
        promotionCode: "",
        bookingReferenceTicketNumber: "",
        bookingReferenceTicketNumberRopNumber: "",
        lastName: "",
        flightNumber: "",
        date: "",
      };
    },
    resetBookFlightLabel: (state: any, action: PayloadAction<any>) => {
      const { fieldLabel } = action.payload;
      if (fieldLabel === "passengers") {
        state.fieldData[fieldLabel] = {};
      } else {
        state.fieldData[fieldLabel] = "";
      }
    },
    updateDropdownOptions: (state: any, action: PayloadAction<any>) => {
      const { fieldLabel, newValue } = action.payload;
      if (newValue.length !== 0) {
        if (fieldLabel.toLowerCase() === BookFlightAirportDropdown.From) {
          state.dropdownOption[BookFlightAirportDropdown.To] = ["BKK"];
        } else {
          state.dropdownOption[BookFlightAirportDropdown.From] = ["AKK"];
        }
      }
    },
    displayMobileModal: (state: any, action: PayloadAction<any>) => {
      const { newValue, label } = action.payload;
      const index = state.mobileModalVisible.findIndex(
        (item: any) => item.label === label
      );
      if (index !== -1) {
        state.mobileModalVisible[index] = {
          ...state.mobileModalVisible[index],
          ...newValue,
        };
      }
    },
    updateModalAirportData: (state: any, action: PayloadAction<any>) => {
      const { newValue, label } = action.payload;
      const index = state.mobileModalVisible.findIndex(
        (item: any) => item.label === label
      );
      if (index !== -1) {
        state.mobileModalVisible[index] = {
          ...state.mobileModalVisible[index],
          ...newValue,
        };
      }
    },
    resetModalAirportData: (state: any) => {
      state.mobileModalVisible = [
        { visible: false, label: "from", airportDetails: null },
        { visible: false, label: "to", airportDetails: null },
      ];
    },
    updateSelectedCardData: (state: any, action: PayloadAction<any>) => {
      const { cardData } = action.payload;
      state.selectedRecentSearchData = cardData;
      if (cardData) {
        state.resetBookFlightTab = false;
      } else {
        state.resetBookFlightTab = true;
      }
    },
    updateSelectedCardDataMobile: (state: any, action: PayloadAction<any>) => {
      const { cardData } = action.payload;
      state.selectedRecentSearchDataMobile = cardData;
      if (cardData) {
        state.resetBookFlightTab = false;
      } else {
        state.resetBookFlightTab = true;
      }
    },
    updateFromDestinationState: (state: any, action: PayloadAction<any>) => {
      const { value } = action.payload;
      state.fromReset = value;
    },
    saveAccessedActiveTab: (state: any, action: PayloadAction<any>) => {
      state.tabIndex = action.payload;
    },
  },
});

export const {
  airportDataRequest,
  getAirportDataSuccess,
  getAirportDataFailure,
  updateFieldData,
  resetFieldData,
  resetBookFlightLabel,
  updateDropdownOptions,
  displayMobileModal,
  updateModalAirportData,
  resetModalAirportData,
  updateSelectedCardData,
  updateSelectedCardDataMobile,
  updateFromDestinationState,
  saveAccessedActiveTab,
} = bookingWidgetSlice.actions;

export default bookingWidgetSlice.reducer;
