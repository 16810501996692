import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  ResendOTPData, ResendOTPDataState } from "../models/models";

const initialState: ResendOTPDataState= {
  resendOTPDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus:"pending"
};

const resendOTPSlice = createSlice({
  name: "resendSection",
  initialState,
  reducers: {
   resendOTPPending: (state, action:PayloadAction<ResendOTPData>) => {
      state.isLoading = true;
      state.codeStatus="pending";
    },
    resendOTPSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.resendOTPDetails = action.payload;
      state.codeStatus="success";
    },
    resendOTPFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus="failure";
      state.hasData = false;
    }, 
    resendOTPClear: (state) => {
      console.log("test otp clear", state)
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.resendOTPDetails = {};
      state.codeStatus="pending";
    },
  },
});

export const { resendOTPPending, resendOTPSuccess, resendOTPFail, resendOTPClear } =
resendOTPSlice.actions;

export default resendOTPSlice.reducer;