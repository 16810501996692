import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FooterData, FooterDataState } from "../models/models";

const initialState: FooterDataState = {
  footerData: {} as FooterData,
  isLoading: true,
  error: "",
  hasData: false,
};

const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    getFooterDataPending: (state) => {
      state.isLoading = true;
    },
    getFooterDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.footerData = action.payload;
    },
    getFooterDataError: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getFooterDataPending,
  getFooterDataSuccess,
  getFooterDataError,
} = footerSlice.actions;
export default footerSlice.reducer;
