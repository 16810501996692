import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  PrivilegeDataState, QueryPayload } from "../models/models";

const initialState:PrivilegeDataState = {
  privilegeDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const privilegeSlice = createSlice({
  name: "privilege",
  initialState,
  reducers: {
    getPrivilegeDataRequest: (state, action: PayloadAction<QueryPayload>) => {
      state.isLoading = true;
    },
    getPrivilegeDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.privilegeDetails = action.payload;
    },
    getPrivilegeDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const { getPrivilegeDataRequest, getPrivilegeDataSuccess, getPrivilegeDataFail } =
privilegeSlice.actions;
export default privilegeSlice.reducer;