import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CrmMemberProfileDetails, CRMProfileInfo } from "../models/models";

const initialState: CRMProfileInfo = {
  crmMemberprofileData: {
    firstName: "",
    middleName: "",
    lastName: "",
    salutation: "",
  } as CrmMemberProfileDetails,
  isLoading: false,
  error: "",
  hasData: false,
  crmCountryCodeData : [],
  crmnewsletterPreferenceData :[],
  crmresidenceData : [],
};

const fetchCrmProfileSlice = createSlice({
  name: "CrmProfileDeails",
  initialState,
  reducers: {
    fetchCrmMemberProfileRequest: (state) => {
      state.isLoading = true;
    },
    getCrmProfileSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmMemberprofileData = action.payload;
      console.log("crmstate", state);
    },
    getCrmProfileFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmMemberprofileData = {};
    },
    fetchCRMCountryCodeRequest: (state) => {
      state.isLoading = true;
    },
    getCRMCountryCodeSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmCountryCodeData = action.payload;
    },
   getCRMCountryCodeFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmCountryCodeData = [];
    },
    fetchCRMnewsletterPreferenceRequest: (state) => {
      state.isLoading = true;
    },
    getCRMnewsletterPreferenceSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      let preference:any = [];
      action.payload.map((item:any)=>{
          preference.push({city :item.city, country : item.city+" " + "-" + " " +item.country, code : item.code,airportCityCode: item.airportCityCode})
      })
      state.crmnewsletterPreferenceData = preference;
    },
   getCRMnewsletterPreferenceFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmnewsletterPreferenceData = [];
    },
    fetchCRMresidenceRequest: (state) => {
      state.isLoading = true;
    },
    getCRMresidenceSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmresidenceData = action.payload;
    },
   getCRMresidenceFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmresidenceData = [];
    },
  },
});

export const {
  fetchCrmMemberProfileRequest,
  getCrmProfileSuccess,
  getCrmProfileFailure,
  fetchCRMCountryCodeRequest,
  getCRMCountryCodeSuccess,
  getCRMCountryCodeFailure,
  fetchCRMnewsletterPreferenceRequest,
  getCRMnewsletterPreferenceSuccess,
  getCRMnewsletterPreferenceFailure,
  fetchCRMresidenceRequest,
  getCRMresidenceSuccess,
  getCRMresidenceFailure
} = fetchCrmProfileSlice.actions;

export default fetchCrmProfileSlice.reducer;
