import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface FlightStatusData {
  flightNumberDetail: any | null;
  flightNumberInputs: any | null;
  selectedRecentSearchData: any | null;
  flightRoute: any | null;
  isLoading: boolean;
  isError: boolean;
  error: boolean;
}

const initialState: FlightStatusData = {
  flightNumberDetail: null,
  flightNumberInputs: null,
  selectedRecentSearchData: null,
  flightRoute: null,
  isLoading: false,
  isError: false,
  error: false,
};

const FlightStatusSlice = createSlice({
  name: "flightStatus",
  initialState,
  reducers: {
    flightStatusByNumberRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    flightStatusByNumberSuccess: (state: any, action: PayloadAction<any>) => {
      state.flightNumberDetail = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    flightStatusByNumberFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    },
    flightStatusNumberInputSave: (state: any, action: PayloadAction<any>) => {
      state.flightNumberInputs = action.payload;
    },
    flightStatusNumberClear: (state: any) => {
      state.flightNumberDetail = null;
      state.flightNumberInputs = null;
      state.flightRoute = null;
      state.isLoading = false;
      state.error = false;
      state.isError = false;
    },
    updateFlightSelectedCardData: (state: any, action: PayloadAction<any>) => {
      const { cardData } = action.payload;
      state.selectedRecentSearchData = cardData;
    },
  },
});

export const {
  flightStatusByNumberRequest,
  flightStatusByNumberSuccess,
  flightStatusByNumberFailure,
  flightStatusNumberInputSave,
  flightStatusNumberClear,
  updateFlightSelectedCardData,
} = FlightStatusSlice.actions;

export default FlightStatusSlice.reducer;
