import { combineReducers } from "@reduxjs/toolkit";
import homeReducer from "./homeSlice";
import bookingWidgetReducer from "./bookingWidgetSlice";
import promotionReducer from "./promotionSlice";
import headerReducer from "./header/header-slice";
import footerReducer from "./footerSlice";
import secondaryCarouselReducer from "./secondaryCarouselSlice";
import tertiaryCarouselReducer from "./tertiaryCarouselSlice";
import preferenceReducer from "./preferenceSlice";
import ropReducer from "./ropSlice";
import modalSlice from "./modalSlice";
import geolocationSlice from "./header/geolocationSlice";
import countrySlice from "./header/countrySlice";
import rohSlice from "./rohSlice";
import thaiCardApplicationSlice from "./thaiCardApplicationSlice";
import advertisementSlice from "./advertisementSlice";
import privilegeSlice from "./privilegeSlice";
import adCarouselReducer from "./adCarouselSlice";
import sawasdeeMagSlice from "./sawasdeeMagSlice";
import applicationCardReducer from "./applicationCardSlice";
import ropMemberJoinReducer from "./ropMemberJoinSlice";
import signUpReducer from "./signUpSlice";
import notificationSliceReducer from "./notificationSlice";
import promotionCardReducer from "./promotionCardSlice";
import appStoreSlice from "./appStoreSlice";
import loginSlice from "./loginSlice";
import otpSlice from "./otpSlice";
import resetReducer from "./resetSlice";
import moreServicesReducer from "./moreServicesSlice";
import flightInfoSlice from "./flightInfoSlice";
import promoCodeSlice from "./promoCode-Slice";
import resendOTPReducer from "./resendSlice";
import globalSearchReducer from "./globalSearchSlice";
import additionalServiceSlice from "./additionalServiceSlice";
import tripSlice from "./tripSlice";
import crmLoginReducer from "./crmLoginSlice";
import redemptionSlice from "./redemptionSlice";
import ropSignUpSlice from "./ropSignUpSlice";
import ropSinUpSlice from "./ropSinUpSlice";
import fetchProfileSlice from "./fetchProfileSlice";
import flightListsSlice from "./flightListsSlice";
import newsAnnouncementReducer from "./newsSlice";
import resetCRMSlice from "./resetCRMSlice";
import fetchNomineeSlice from "./fetchNomineeSlice";
import flightFareSlice from "./flightFareSlice";
import unsubscribeSlice from "./unsubscribeSlice";
import subscribeSlice from "./subscribeSlice";
import reSubscribeSlice from "./resubscribeSlice";
import idCarouselReducer from "./idCarouselSlice";
import languageSliceReducer from "./languageSlice";
import crmSignUpReducer from "./crmSignUpSlice";
import socialMediaSlice from "./socialMediaSlice";
import flightStatusSlice from "./flightStatusSlice";
import flightStatusRouteSlice from "./flightRouteStatusSlice";
import logOutReducer from "./logOutSlice";
import preferenceFromSlice from "./newsletterPreferenceSlice";
import profileContactInfoSlice from "./profileContactInfoSlice";
import fetchMainProfileSlice from "./fetchMainMemberProfileSlice";
import fetchCrmProfileSlice from "./fetchCrmProfileSlice";
import fetchMainMemberMilesSlice from "./fetchMilesSlice";
import crmOTPSlice from "./crmOTPSlice";
import pnrDetailsSlice from "./pnrDetailsSlice";
import calendarPricingSlice from "./calendarPricingSlice";
import airAwardBookingSlice from "./airAwardBookingSlice";
import airAwardPnrSlice from "./airAwardPnrSlice";
import airAwardPriceSlice from "./airAwardPriceSlice";
import airAwardTstSlice from "./airAwardTstSlice";
import retrivePNRSlice from "./retrivePNRSlice";
import cancelBookingPNRSlice from "./cancelBookingPNRSlice";
import myProfileDeailsSlice from "./fetchLifeStyleSlice";
import profileLifeStyleSlice from "./profileLifeStyleSlice";
import airAwardReviewPnrSlice from "./airAwardReviewPnrSlice";
import deleteTripCardSlice from "./deleteTripCardSlice";
import airAwardAirportSlice from "./airAwardAirportSlice";
import profilePersonalInfoSlice from "./profilePersonalInfoSlice";
import errorModalSlice from "./errorModalSlice";
import changePasswordSlice from "./changePasswordSlice";
import changePasswordOTPSlice from "./changePasswordOTPSlice";
import changePasswordOTPResendSlice from "./changePasswordOTPResendSlice";
import mileageStatementSlice from "./mileageStatementSlice";
import crmResendOTPSlice from "./crmResendOTPSlice";
import eReceiptSlice from "./eReceiptSlice";
import airAwardReviewPnrRMSlice from "./airAwardReviewPnrRMSlice";
import loginPanelSlice from "./loginPanelSlice";
import myTripsSlice from "./myTripsSlice";
import airAwardReviewPnrCancelSlice from "./airAwardReviewPnrCancelSlice";
import backTrackingAirportSlice from "./backTrackingAirportSlice";
import helpSlice from "./header/helpSlice";
import airAwardPaymentInitSlice from "./airAwardPaymentInitSlice";
import addNomineeSlice from "./addNomineeSlice";
import memberStatusCalculatorSlice from "./fetchMemberStatusCalculatorSlice";
import giftMilesSlice from "./giftMilesSlice";
import baggageAllowanceSlice from "./baggageAllowanceSlice";
import airAwardConfirmationSlice from "./airAwardConfirmationSlice";

const RootReducer: any = combineReducers({
  home: homeReducer,
  header: headerReducer,
  footer: footerReducer,
  promotionData: promotionReducer,
  ropData: ropReducer,
  secondaryCarousel: secondaryCarouselReducer,
  tertiaryCarousel: tertiaryCarouselReducer,
  bookingWidget: bookingWidgetReducer,
  preference: preferenceReducer,
  modals: modalSlice,
  geolocation: geolocationSlice,
  country: countrySlice,
  rohData: rohSlice,
  thaicard: thaiCardApplicationSlice,
  advertisement: advertisementSlice,
  privilegeData: privilegeSlice,
  adCarousel: adCarouselReducer,
  swMagzine: sawasdeeMagSlice,
  applicationcard: applicationCardReducer,
  ropmemberjoin: ropMemberJoinReducer,
  signUp: signUpReducer,
  notification: notificationSliceReducer,
  promotionCard: promotionCardReducer,
  app: appStoreSlice,
  loginData: loginSlice,
  otpData: otpSlice,
  reset: resetReducer,
  moreServices: moreServicesReducer,
  promoCode: promoCodeSlice,
  resendOTPData: resendOTPReducer,
  globalSearch: globalSearchReducer,
  additonalService: additionalServiceSlice,
  trips: tripSlice,
  crmLoginData: crmLoginReducer,
  redemption: redemptionSlice,
  ropSignup: ropSignUpSlice,
  ropSin: ropSinUpSlice,

  profile: fetchProfileSlice,
  selectedRoutes: flightListsSlice,
  flightInfo: flightInfoSlice,
  crmReset: resetCRMSlice,
  news: newsAnnouncementReducer,
  nominee: fetchNomineeSlice,
  addNominee: addNomineeSlice,
  flightFare: flightFareSlice,
  idCarousel: idCarouselReducer,
  languageSlice: languageSliceReducer,
  crmSignUp: crmSignUpReducer,
  socialMedia: socialMediaSlice,
  unsubscribeReset: unsubscribeSlice,
  subscribeReset: subscribeSlice,
  resubscribeReset: reSubscribeSlice,

  flightStatus: flightStatusSlice,
  flightStatusRoute: flightStatusRouteSlice,
  logout: logOutReducer,
  preferenceFrom: preferenceFromSlice,
  crmotpData: crmOTPSlice,
  profileContactInfo: profileContactInfoSlice,
  MyProfileDeails: fetchMainProfileSlice,
  pnr: pnrDetailsSlice,
  dateFare: calendarPricingSlice,
  MyProfileMilesDeails: fetchMainMemberMilesSlice,
  airAwardBooking: airAwardBookingSlice,
  airAwardPnr: airAwardPnrSlice,
  airAwardPrice: airAwardPriceSlice,
  airAwardTst: airAwardTstSlice,
  retrivePNRData: retrivePNRSlice,
  cancelBookingPNRData: cancelBookingPNRSlice,
  myLifeStyleData: myProfileDeailsSlice,
  profileLifeStyleSubmitResponse: profileLifeStyleSlice,
  airAwardReviewPnr: airAwardReviewPnrSlice,
  deleteTripCard: deleteTripCardSlice,
  airAwardAirport: airAwardAirportSlice,
  profilePersonalInfo: profilePersonalInfoSlice,
  fetchCrmMemberProfile: fetchCrmProfileSlice,
  errorModal: errorModalSlice,
  changePassword: changePasswordSlice,
  changePasswordotpData: changePasswordOTPSlice,
  changePasswordResend: changePasswordOTPResendSlice,
  mileageStatement: mileageStatementSlice,
  crmResendOTPData: crmResendOTPSlice,
  eReceipt: eReceiptSlice,
  airAwardReviewPnrRM: airAwardReviewPnrRMSlice,
  loginPanel: loginPanelSlice,
  myTrips: myTripsSlice,
  airAwardReviewPnrCancel: airAwardReviewPnrCancelSlice,
  backTrackingAirport: backTrackingAirportSlice,
  help: helpSlice,
  airAwardPayment: airAwardPaymentInitSlice,
  memberStatusCalculator: memberStatusCalculatorSlice,
  giftMiles: giftMilesSlice,
  baggageAllowance: baggageAllowanceSlice,
  airAwardConfirmation: airAwardConfirmationSlice
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
