import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LogoutData, LogoutDataState } from "../models/models";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const initialState: LogoutDataState = {
  logoutDetails: {} as LogoutData,
  crmLogoutData: {},
  isLoading: false,
  error: false,
  codeStatus: "pending",
  navigateRop: false
};

function handleLogoutState(
  logoutDetails: LogoutData,
  codeStatus: string,
  navigate?: boolean
) {
  if (codeStatus === "success") {
    if (logoutDetails.code === "200" || logoutDetails?.success) {
      if (navigate) {
        sessionStorage.clear();
        sessionStorage.setItem("navigateRop", "true");
        window.location.reload();
      } else {
        sessionStorage.clear();
        window.location.reload();
      }
    }
  } else if (codeStatus === "failure") {
    sessionStorage.clear();
    window.location.reload();
  }
}

const LogOutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
    },
    getLogOutSuccess: (state: any, action: PayloadAction<any>) => {
      state.logoutDetails = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success",state.navigateRop);
    },
    getLogOutFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    crmLogoutRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
      state.codeStatus = "pending";
    },
    crmGetLogOutSuccess: (state: any, action: PayloadAction<any>) => {
      state.crmLogoutData = action.payload;
      state.isLoading = false;
      state.codeStatus = "success";
      handleLogoutState(action.payload, "success");
    },
    crmGetLogOutFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.codeStatus = "failure";
      handleLogoutState(action.payload, "failure");
    },
    setNavigateRop:(state: any, action: PayloadAction<any>) => {
      state.navigateRop = action.payload;
    },
  },
});

export const {
  logoutRequest,
  getLogOutSuccess,
  getLogOutFailure,
  crmLogoutRequest,
  crmGetLogOutSuccess,
  crmGetLogOutFailure,
  setNavigateRop
} = LogOutSlice.actions;

export default LogOutSlice.reducer;
