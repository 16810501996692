import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any  = {
    preferences: {},
    isLoading: false,
    error: false,
    language: null,
    country: null
};

const preferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        getPreferenceRequest: (state: any) => {
            state.isLoading = true;
            state.error = null;
        },
        getPreferenceSuccess: (state: any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = "";
            state.hasData = true;
            state.preferences = action.payload;
            state.country = action.payload.name;
            state.language = action.payload.name;
        },
        getPreferenceError: (state: any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setCountry: (state, action) => {
            state.country = action.payload;
        },
    },
});

export const {getPreferenceRequest, getPreferenceSuccess, getPreferenceError, setLanguage, setCountry} = preferenceSlice.actions;
export const selectLanguage = (state: any) => state.preference.language;
export const selectCountry = (state: any) => state.preference.country;
export default preferenceSlice.reducer;