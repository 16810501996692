import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ROPCode, ROPSignUpDataState } from "../models/models";

const initialState: ROPSignUpDataState = {
  ropSignupDetails: {} as ROPCode,
  isLoading: false,
  error:"",
  hasData: false,
  codeStatus: "pending",
  ropStatus: { status: false, message: "" },
};

const ropSignupSlice = createSlice({
  name: "ropSignup",
  initialState,
  reducers: {
    getRopSignUpDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getRopSignUpSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.ropSignupDetails = action.payload;
      if(state.ropSignupDetails.code =="I110")
        {
          console.log("eneter success");
          state.ropStatus.message ="Member Added";
          state.ropStatus.status = true;
          sessionStorage.setItem('memberId',state.ropSignupDetails.memberId);
        }
 
      state.codeStatus = "success";
     
    },
    getRopSignupFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.ropSignupDetails = action.payload.response.data;
      if(state.ropSignupDetails.code == "I850" )
        {
          state.ropStatus.message ="Duplicate";
          state.ropStatus.status = false;
        }else{
          state.ropStatus.message ="Something went wrong";
          state.ropStatus.status = false;
        }
  
    },
    updateSignInState: (state: any, action: PayloadAction<any>) => {
      const { updatedState } = action.payload;
      state.isSignin = updatedState;
    },

  },
});

export const {
  getRopSignUpDataPending,
  getRopSignupFailure,
  getRopSignUpSuccess,
  updateSignInState
} = ropSignupSlice.actions;

export default ropSignupSlice.reducer;
