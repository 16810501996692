import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  myLifeStyleSubmitData: {},
  isLoading: false,
  error: "",
  hasData: false,
};

const fetchLifeStyleSlice = createSlice({
  name: "MyLifeStyleDeails",
  initialState,
  reducers: {
    fetchLifeStyleRequest: (state) => {
        state.isLoading = true;
      },
      getLifeStyleSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myLifeStyleSubmitData = action.payload;
      },
      getLifeStyleFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
        state.hasData = false;
        state.myLifeStyleSubmitData = {}
      }
  },
});

export const {
    fetchLifeStyleRequest,
    getLifeStyleSuccess,
    getLifeStyleFailure,
  } = fetchLifeStyleSlice.actions;
  
  export default fetchLifeStyleSlice.reducer;
  