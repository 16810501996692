import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  myprofileData: {},
  isLoading: false,
  error: "",
  hasData: false,
  crmNationalityData :{}
};

const fetchMainProfileSlice = createSlice({
  name: "MyProfileDeails",
  initialState,
  reducers: {
    fetchMyProfileRequest: (state) => {
        state.isLoading = true;
      },
      getMyProfileSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myprofileData = action.payload;
      },
      getMyProfileFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
        state.hasData = false;
        state.myprofileData = {}
      },
      fetchCRMNatinalityRequest: (state) => {
        state.isLoading = true;
      },
      getCRMNatinalitySuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmNationalityData = action.payload;
      },
      getCRMNatinalityFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
        state.hasData = false;
        state.crmNationalityData = {}
      }
  },
});

export const {
    fetchMyProfileRequest,
    getMyProfileSuccess,
    getMyProfileFailure,
  fetchCRMNatinalityRequest,
  getCRMNatinalitySuccess,
  getCRMNatinalityFailure
  } = fetchMainProfileSlice.actions;
  
  export default fetchMainProfileSlice.reducer;
  