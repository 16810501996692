import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardReviewPnrRMInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardReviewPnrRMSlice = createSlice({
  name: "airAwardReviewPnrRM",
  initialState,
  reducers: {
    airAwardReviewPnrRMRequest: (state) => {
      state.isLoading = true;
    },
    airAwardReviewPnrRMSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardReviewPnrRMInfo = action.payload;
    },
    airAwardReviewPnrRMFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardReviewPnrRMReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardReviewPnrRMInfo = {};
    },
  },
});

export const {
    airAwardReviewPnrRMRequest,
    airAwardReviewPnrRMSuccess,
    airAwardReviewPnrRMFailure,
    airAwardReviewPnrRMReset
} = airAwardReviewPnrRMSlice.actions;

export default airAwardReviewPnrRMSlice.reducer;
