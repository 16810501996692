import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardPaymentInitInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardPaymentInitSlice = createSlice({
  name: "airAwardPaymentInit",
  initialState,
  reducers: {
    airAwardPaymentInitRequest: (state) => {
      state.isLoading = true;
    },
    airAwardPaymentInitSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPaymentInitInfo = action.payload;
    },
    airAwardPaymentInitFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardPaymentInitReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPaymentInitInfo = {};
    },
  },
});

export const {
    airAwardPaymentInitRequest,
    airAwardPaymentInitSuccess,
    airAwardPaymentInitFailure,
    airAwardPaymentInitReset
} = airAwardPaymentInitSlice.actions;

export default airAwardPaymentInitSlice.reducer;
