import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModalData, ModalDataObj } from "../models/models";

export interface modalData {
  data: null | ModalDataObj;
  isLoading: Boolean;
  error: Boolean;
}

const initialState: any = {
  data: null,
  isLoading: false,
  error: false,
};

const ModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    modalDataRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
    },
    getModalDataSuccess: (state: any, action: PayloadAction<ModalData>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    getModalDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { modalDataRequest, getModalDataSuccess, getModalDataFailure } =
  ModalSlice.actions;

export default ModalSlice.reducer;
