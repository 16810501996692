import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CRMCode, CRMSignUpDataState } from "../models/models";

const initialState: CRMSignUpDataState = {
  crmSignupDetails: {} as CRMCode,
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  crmStatus: { status: false, message: "" },
  crmSignupSocialMediaDetails: {},
  crmSignupSocialMediaErrors: "",
  crmSignupSocialMediaLoading: false,
};

const crmSignupSlice = createSlice({
  name: "crmSignup",
  initialState,
  reducers: {
    getCrmSignUpDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCrmSignUpSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.crmSignupDetails = action.payload;
      if (state.crmSignupDetails.statusMessage == "Success") {
        state.crmStatus.message = "Member Added";
        state.crmStatus.status = true;
        sessionStorage.setItem("email", action.payload.email);
      }

      state.codeStatus = "success";
    },
    getCrmSignupFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.crmSignupDetails = action.payload.response.data;
      if (state.crmSignupDetails.Code == "400") {
        if (
          state.crmSignupDetails.Message.includes("The email has already used")
        ) {
          state.crmStatus.message = "Duplicate";
          state.crmStatus.status = false;
        } else {
          state.crmStatus.message = "GenericError";
          state.crmStatus.status = false;
        }
      }
    },
    updateSignInState: (state: any, action: PayloadAction<any>) => {
      const { updatedState } = action.payload;
      state.isSignin = updatedState;
    },
    clearCrmSignUpResponse: (state) => {
      state.crmStatus = { status: false, message: "" };
    },
    getCrmSignUpSocialMediaDataPending: (state, action: PayloadAction<any>) => {
      state.crmSignupSocialMediaLoading = true;
      state.codeStatus = "pending";
    },
    getCrmSignUpocialMediaSuccess: (state, action: PayloadAction<any>) => {
      state.crmSignupSocialMediaLoading = false;
      state.crmSignupSocialMediaDetails = action.payload;
      if (state.crmSignupSocialMediaDetails.success) {
        state.crmStatus.message = "Member Added";
        state.crmStatus.status = true;
        sessionStorage.setItem("email", action.payload.email);
      } else if (
        state.crmSignupSocialMediaDetails.response.data.Status === 400
      ) {
        if (
          state.crmSignupSocialMediaDetails.response.data.Message.includes(
            "Cannot create user. The email or client_id already exists."
          )
        ) {
          state.crmStatus.message = "Duplicate";
          state.crmStatus.status = false;
        } else {
          state.crmStatus.message = "GenericError";
          state.crmStatus.status = false;
        }
      } else {
        state.crmStatus.message = "GenericError";
        state.crmStatus.status = false;
      }
      state.codeStatus = "success";
    },
    getCrmSignUpSocialMediaFailure: (state, action: PayloadAction<any>) => {
      state.crmSignupSocialMediaLoading = false;
      state.codeStatus = "failure";
      state.crmSignupSocialMediaDetails = action.payload;
      //Error handling
    },
  },
});

export const {
  getCrmSignUpDataPending,
  getCrmSignupFailure,
  getCrmSignUpSuccess,
  updateSignInState,
  clearCrmSignUpResponse,
  getCrmSignUpSocialMediaDataPending,
  getCrmSignUpocialMediaSuccess,
  getCrmSignUpSocialMediaFailure,
} = crmSignupSlice.actions;

export default crmSignupSlice.reducer;
