import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResetUnSubscription, UnsubscribeState, ResetUnsubscriptionError } from "../models/models";

const initialState: UnsubscribeState  = {
  unsubscribeDetails: {} as ResetUnSubscription,
  isLoading: false,
  error:{} as ResetUnsubscriptionError,
  hasData: false,
  unsubscribestatus: { status: false, message: "" },
};

const unSubscribeResetSlice = createSlice({
  name: "unsubscribeReset",
  initialState,
  reducers: {
    getUnsubscribeDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getUnSubscribeSuccess: (state, action: PayloadAction<any>) => {
      console.log("UnSubscribe playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.unsubscribeDetails = action.payload;
      state.unsubscribestatus.status = true;
    },
    getUnSubscribeFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.unsubscribestatus.status = false;
      if(state.error.code == "400" )
        {
          state.unsubscribestatus.message ="Invalid Email";
          state.unsubscribestatus.status = false;
        }
  
    },
    resetUnSubscribeData: (state) => {
      state.error={} as ResetUnsubscriptionError;
      state.isLoading = false;
      state.hasData=false;
      state.unsubscribeDetails = {} as ResetUnSubscription;
      state.unsubscribestatus = { status: false, message: "" }
    }, 
  },
});

export const {
    getUnsubscribeDataPending,
    getUnSubscribeFailure,
    getUnSubscribeSuccess,
    resetUnSubscribeData
} = unSubscribeResetSlice.actions;

export default unSubscribeResetSlice.reducer;
