import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightFareState } from "../models/models";

const initialState: FlightFareState = {
  data: {},
  paxInfo: [],
  flightInfo: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const flightFareSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    flightFareRequest: (state) => {
      state.isLoading = true;
    },
    flightFareSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    flightFareFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { flightFareRequest, flightFareSuccess, flightFareFail } =
  flightFareSlice.actions;

export default flightFareSlice.reducer;
