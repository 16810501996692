import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HelpDetails, HelpDataState } from "../../models/models";

const initialState: HelpDataState = {
  helpDetails: {} as HelpDetails,
  isLoading: false,
  error: "",
  hasData: false,
};

const helpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {
    helpDataRequest: (state) => {
      state.isLoading = true;
    },
    helpDataSuccess: (state: HelpDataState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.helpDetails = action.payload;
    },
    helpDataFailure: (state: HelpDataState, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { helpDataRequest, helpDataFailure, helpDataSuccess } =
  helpSlice.actions;

export default helpSlice.reducer;
