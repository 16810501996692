import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteTripCard, tripDetails } from "../models/models";

const initialState: deleteTripCard = {
    pnrDetails: [],
    isLoading: false,
    hasData: false,
    error: "",
    pnr: "",
    tripCardStatus: { status: false, message: "" },
};

  
const deleteTripCardSlice = createSlice({
    name: "deleteTripCard",
    initialState,
    reducers: {
      deleteTripCardDataRequest: (state: any, action: PayloadAction<any>) => {
        state.isLoading = true;
        state.pnr = action.payload;
        state.hasData = false;
      },
      deleteTripCardDataSuccess: (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = "";
        state.hasData = true;
        state.upcomingTrips = action.payload as tripDetails;
        state.tripCardStatus.message = "Trip has been deleted";
        state.tripCardStatus.status = true;
      },
      deleteTripCardDataFailure: (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
        state.tripCardStatus.status = false;
      },
      resetTripCardState:(state: any) => {
        state.tripCardStatus.status = false;
      }
    },
  });
  
  export const { deleteTripCardDataRequest, deleteTripCardDataSuccess, deleteTripCardDataFailure, resetTripCardState } =
  deleteTripCardSlice.actions;
  
  export default deleteTripCardSlice.reducer;
  
