import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppStoreDataState } from "../models/models";

const initialState: AppStoreDataState = {
  appStoreDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const appStoreSlice = createSlice({
  name: "appStore",
  initialState,
  reducers: {
    appStoreDataRequest: (state) => {
      state.isLoading = true;
    },
    getAppStoreDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.appStoreDetails = action.payload;
    },
    getAppStoreDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {appStoreDataRequest, getAppStoreDataSuccess, getAppStoreDataFailure} = appStoreSlice.actions;

export default appStoreSlice.reducer;