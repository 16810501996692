import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ThaiCardState } from "../models/models";

const initialState: ThaiCardState = {
  ThaiCardDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const thaiCardSlice = createSlice({
  name: "thaicard",
  initialState,
  reducers: {
    thaiCardDataRequest: (state) => {
      state.isLoading = true;
    },
    getThaiCardDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ThaiCardDetails = action.payload;
    },
    getThaiCardDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  thaiCardDataRequest,
  getThaiCardDataFailure,
  getThaiCardDataSuccess,
} = thaiCardSlice.actions;

export default thaiCardSlice.reducer;
