import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  isLoading: false,
  error: "",
  hasData: false,
  otpDetails: {},
  codeStatus: "",
  statusMessage: false,
  codeExpired: false,
};

const addNomineeSlice = createSlice({
  name: "addNominee",
  initialState,
  reducers: {
    sendNomineeOTPRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    sendNomineeOTPSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem(
        "refNumber",
        JSON.stringify(action?.payload?.otpRefKey)
      );
    },
    sendNomineeOTPFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
    },
    resendNomineeOTPRequest: (state: any) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    resendNomineeOTPSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem(
        "refNumber",
        JSON.stringify(action?.payload?.otpRefKey)
      );
    },
    resendNomineeOTPFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    submitNomineeOTPRequest: (state: any) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    submitNomineeOTPSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus = "success";
      state.statusMessage = true;
    },
    submitNomineeOTPFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
    },
    resetNomineeOTPData: (state, action: PayloadAction<any>) => {
      state.codeStatus = "pending";
      state.error = "";
      state.otpDetails = [];
      state.hasData = false;
      state.codeExpired = action.payload;
    },
  },
});

export const {
  sendNomineeOTPRequest,
  sendNomineeOTPFail,
  sendNomineeOTPSuccess,
  resendNomineeOTPRequest,
  resendNomineeOTPSuccess,
  resendNomineeOTPFail,
  submitNomineeOTPRequest,
  submitNomineeOTPFail,
  submitNomineeOTPSuccess,
  resetNomineeOTPData,
} = addNomineeSlice.actions;

export default addNomineeSlice.reducer;
