import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationCardDataState } from "../models/models";

const initialState: ApplicationCardDataState = {
  applicationCardData: null,
  isLoading: false,
  error: "",
  hasData: false,
};

const applicationCardSlice = createSlice({
  name: "applicationcard",
  initialState,
  reducers: {
    getApplicationCardDataRequest: (state) => {
      state.isLoading = true;
    },
    getApplicationCardDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.applicationCardData = action.payload;
    },
    getApplicationCardDataError: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getApplicationCardDataRequest,
  getApplicationCardDataSuccess,
  getApplicationCardDataError,
} = applicationCardSlice.actions;
export default applicationCardSlice.reducer;
