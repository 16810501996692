import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardPnrInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardPnrSlice = createSlice({
  name: "airAwardPnr",
  initialState,
  reducers: {
    airAwardPnrRequest: (state) => {
      state.isLoading = true;
    },
    airAwardPnrSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPnrInfo = action.payload;
    },
    airAwardPnrFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardPnrReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPnrInfo = {};
    },
  },
});

export const {
    airAwardPnrRequest,
    airAwardPnrSuccess,
    airAwardPnrFailure,
    airAwardPnrReset
} = airAwardPnrSlice.actions;

export default airAwardPnrSlice.reducer;
