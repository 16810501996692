import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { moreServicesData, moreServicesDataState } from "../models/models";

const initialState: moreServicesDataState = {
  moreServicesDetails: {} as moreServicesData,
  isLoading: true,
  error: "",
  hasData: false,
};

const moreServicesSlice = createSlice({
  name: "moreServices",
  initialState,
  reducers: {
    getMoreServicesDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.moreServicesDetails = action.payload;
      //storing in session storage
      sessionStorage.setItem("moreServices", JSON.stringify(action.payload));
    },
    getMoreServicesDataFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getMoreServicesDataSuccess, getMoreServicesDataFailure } =
  moreServicesSlice.actions;

export default moreServicesSlice.reducer;
