import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalSearchPreData, HeaderData } from "../../models/models";

export interface headerData {
  data: null | HeaderData;
  globalSearchPreData: null | GlobalSearchPreData;
  isLoading: Boolean;
  error: Boolean;
}

const initialState: any = {
  headerDetails: [],
  data: null,
  countryData: null,
  globalSearchPreData: null,
  isLoading: false,
  error: false,
};

const HeaderSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    headerDataRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
    },
    getHeaderDataSuccess: (state: any, action: PayloadAction<HeaderData>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    getHeaderDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    globalSearchPreRenderDataRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
    },
    globalSearchPreRenderDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.globalSearchPreData = action.payload;
      state.isLoading = false;
    },
    globalSearchPreRenderDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  },
});

export const {
  headerDataRequest,
  getHeaderDataSuccess,
  getHeaderDataFailure,
  globalSearchPreRenderDataRequest,
  globalSearchPreRenderDataSuccess,
  globalSearchPreRenderDataFailure
} = HeaderSlice.actions;

export default HeaderSlice.reducer;
