import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResetFormData, ResetDataState, ResetResponse } from "../models/models";

const initialState: ResetDataState = {
  ResetDetails: {} as ResetResponse,
  isLoading: false,
  error: {} ,
  hasData: false,
};

const resetSlice = createSlice({
  name: "reset",
  initialState,
  reducers: {
    resetDataRequest: (state, action: PayloadAction<ResetFormData>) => {
      state.isLoading = true;
      state.error = "";
    },
    resetDataSuccess: (state: any, action: PayloadAction<ResetResponse>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ResetDetails = action.payload;
    },
    resetDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { resetDataRequest, resetDataSuccess, resetDataFailure } =
  resetSlice.actions;

export default resetSlice.reducer;
