import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface data {
  isPanelClosed: Boolean;
  userName: string;
}

const initialState: data = {
  isPanelClosed: false,
  userName: "",
};

const LoginPanelSlice = createSlice({
  name: "loginpanel",
  initialState,
  reducers: {
    setIsPanelClosed: (state: any, action: PayloadAction<boolean>) => {
      state.isPanelClosed = action.payload;
    },
    setUserName: (state: any, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setIsPanelClosed, setUserName } = LoginPanelSlice.actions;

export default LoginPanelSlice.reducer;
