import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  ROHDataState, QueryPayload } from "../models/models";

const initialState:ROHDataState = {
  rohDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const rohSlice = createSlice({
  name: "roh",
  initialState,
  reducers: {
    getROHDataRequest: (state, action: PayloadAction<QueryPayload>) => {
      state.isLoading = true;
    },
    getROHDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.rohDetails = action.payload;
    },
    getROHDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const { getROHDataRequest, getROHDataSuccess, getROHDataFail } =
rohSlice.actions;
export default  rohSlice.reducer;