import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ErrorDetails {
  showErrorModal: boolean;
  errorDetails: any;
  errorTriggered: boolean;
  buttonAction: any;
}

const initialState: ErrorDetails = {
  showErrorModal: false,
  errorDetails: {},
  errorTriggered: false,
  buttonAction: {},
};

const errorModalSlice = createSlice({
  name: "errorModal",
  initialState,
  reducers: {
    setError: (state: any, action: PayloadAction<any>) => {
      if (!state.errorTriggered) {
        state.showErrorModal = true;
        state.errorDetails = action.payload;
        state.errorTriggered = true;
      }
    },
    clearError: (state: any) => {
      state.showErrorModal = false;
      state.errorDetails = {};
      state.errorTriggered = false;
    },
    setButtonAction: (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        state.buttonAction = { buttonLabel: "primary", value: true };
      } else {
        state.buttonAction = { buttonLabel: "secondary", value: false };
      }
      state.showErrorModal = false;
    },
    resetButtonAction: (state: any) => {
      state.buttonAction = {};
    },
    resetModalTriggered: (state: any) => {
      state.errorTriggered = false;
      state.showErrorModal = false;
    },
  },
});

export const {
  setError,
  clearError,
  setButtonAction,
  resetButtonAction,
  resetModalTriggered,
} = errorModalSlice.actions;

export default errorModalSlice.reducer;
