import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CRMLoginDataState } from "../models/models";

const initialState: CRMLoginDataState = {
  crmLoginDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  crmSocialLoginValidationDetails: {},
  crmSocialLoginValidationErrors: "",
  crmSocialLoginValidationLoading: false,
  crmSocialLoginType: "",
};

const crmLoginSlice = createSlice({
  name: "crmLogin",
  initialState,
  reducers: {
    getCrmLoginDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCrmLoginDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmLoginDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNo", action.payload.refNo);

      sessionStorage.setItem("isROPLoggedIn", JSON.stringify(false));
    },
    getCrmLoginDataFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload;
    },
    resetCrmLoginData: (state) => {
      state.codeStatus = "pending";
      state.error = "";
      state.crmLoginDetails = {};
      state.hasData = false;
    },
    getCrmSocialMediaValidationPending: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.crmSocialLoginValidationLoading = true;
      state.crmSocialLoginType = action.payload.socialMediaType;
    },
    getCrmSocialMediaValidationSuccess: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.crmSocialLoginValidationLoading = false;
      let fullName = "";
      if (!action.payload.newUser) {
        fullName =
          action.payload.profileDetails?.salutation + " " +
          action.payload.profileDetails?.firstName +
          " " +
          action.payload?.profileDetails?.lastName;
        sessionStorage.setItem("FullName", fullName);
        sessionStorage.setItem(
          "FirstName",
          action.payload.profileDetails?.firstName
        );
        sessionStorage.setItem(
          "LastName",
          action.payload?.profileDetails?.lastName
        );
        sessionStorage.setItem(
          "Salutation",
          action.payload?.profileDetails?.salutation
        );
        sessionStorage.setItem("profileName", fullName);
        sessionStorage.setItem(
          "customerID",
          action.payload?.profileDetails?.customerID
        );
        sessionStorage.setItem("isROPLoggedIn", "false");
      }
      state.crmSocialLoginValidationDetails = action.payload;
      state.crmSocialLoginValidationErrors = "";
    },
    getCrmSocialMediaValidationFailure: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.crmSocialLoginValidationLoading = false;
      state.crmSocialLoginValidationErrors = action.payload;
    },
    resetCrmSocialValidationData: (state: any) => {
      state.crmSocialLoginValidationDetails = {};
    },
  },
});

export const {
  getCrmLoginDataPending,
  getCrmLoginDataSuccess,
  getCrmLoginDataFailure,
  resetCrmLoginData,
  getCrmSocialMediaValidationPending,
  getCrmSocialMediaValidationSuccess,
  getCrmSocialMediaValidationFailure,
  resetCrmSocialValidationData,
} = crmLoginSlice.actions;

export default crmLoginSlice.reducer;
