import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PreferenceDataState } from "../models/models";

const initialState: PreferenceDataState = {
    countryList: [],
    isLoading: false,
    error: "",
    hasData: false,
};

const preferenceFromSlice = createSlice({
    name: "preferenceFrom",
    initialState,
    reducers: {
        preferenceFromDataRequest: (state) => {
            state.isLoading = true;
        },
        getPreferenceFromDataSuccess: (state: any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = "";
            let preference:any = [];
            action.payload.map((item:any)=>{
                preference.push({city :item.city, country : item.city + "-" + item.country, code : item.code,airportCityCode: item.airportCityCode})
            })
            state.countryList = preference;
        },
        getPreferenceFromDataFailure: (state: any, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    preferenceFromDataRequest,
    getPreferenceFromDataSuccess,
    getPreferenceFromDataFailure,
} = preferenceFromSlice.actions;

export default preferenceFromSlice.reducer;