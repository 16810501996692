import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TermsPrivacyDataState } from "../models/models";

const initialState: TermsPrivacyDataState = {
  modalDetails: [],
  isLoadingModalDetails: false,
  errorModalDetails: "",
  nationDetails: [],
  isLoadingNationDetails: false,
  errorNationDetails: "",
  loginModalVisible: false,
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    termsPrivacyModalDataRequest: (state) => {
      state.isLoadingModalDetails = true;
    },
    getTermsPrivacyModalSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoadingModalDetails = false;
      state.errorModalDetails = "";
      state.modalDetails = action.payload;
    },
    getTermsPrivacyModalFailure: (state: any, action: PayloadAction<any>) => {
      state.errorModalDetails = action.payload;
      state.isLoadingModalDetails = false;
    },
    nationDataRequest: (state) => {
      state.isLoadingNationDetails = true;
    },
    getNationDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoadingNationDetails = false;
      state.errorNationDetails = "";
      state.nationDetails = action.payload;
    },
    getNationDataFailure: (state: any, action: PayloadAction<any>) => {
      state.errorNationDetails = action.payload;
      state.isLoadingNationDetails = false;
    },
    updateLoginModalState: (state: any, action: PayloadAction<any>) => {
      const { value } = action.payload;
      state.loginModalVisible = value;
    },
  },
});

export const {
  termsPrivacyModalDataRequest,
  getTermsPrivacyModalSuccess,
  getTermsPrivacyModalFailure,
  nationDataRequest,
  getNationDataSuccess,
  getNationDataFailure,
  updateLoginModalState,
} = signUpSlice.actions;

export default signUpSlice.reducer;
