import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RetrivePNRState } from "../models/models";

const initialState: RetrivePNRState = {
  data: {},
  isLoading: false,
  error: "",
  hasData: false,
  pnr: "",
  email: "",
  phoneNum: "",
  itineraryInfos: [],
  fareInfo: [],
  taxInfo: [],
  totalFare: "",
  totalMiles: "",
  currency: "",
  session: {
    sessionId: "",
    sequenceNumber: "",
    securityToken: "",
    transactionStatusCode: "",
  },
};

const retrivePNRSlice = createSlice({
  name: "retrivePNRData",
  initialState,
  reducers: {
    retrivePNRRequest: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    retrivePNRSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    retrivePNRFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    retrivePNRReset: (state) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.data = {};
    },
  },
});

export const { retrivePNRRequest, retrivePNRSuccess, retrivePNRFail, retrivePNRReset } =
  retrivePNRSlice.actions;

export default retrivePNRSlice.reducer;
