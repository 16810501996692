import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CancelBookingPNRState } from "../models/models";

const initialState: CancelBookingPNRState = {
  data: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  success: true,
  session: {
    sessionId: "",
    sequenceNumber: "",
    securityToken: "",
    transactionStatusCode: "",
  },
};

const cancelBookingPNRSlice = createSlice({
  name: "cancelBookingPNRData",
  initialState,
  reducers: {
    cancelBookingPNRRequest: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    cancelBookingPNRSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
      state.codeStatus = "success";
    },
    cancelBookingPNRFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "pending";
    },
  },
});

export const {
  cancelBookingPNRRequest,
  cancelBookingPNRSuccess,
  cancelBookingPNRFail,
} = cancelBookingPNRSlice.actions;

export default cancelBookingPNRSlice.reducer;
