import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { eReceiptDataState } from "../models/models";

const initialState: eReceiptDataState = {
  isLoading: false,
  eReceiptBlob: "",
  eReceiptUrl: "",
  error: "",
  profileInfo: {},
  limitDetails:{},
  isUpdateSponsorSuccess:false,
  termsData:{}
};

const eReceiptSlice = createSlice({
  name: "eReceipt",
  initialState,
  reducers: {
    resetEreceiptDataRequest: (state: any) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = "";
      state.profileInfo = {};
      state.limitDetails = {};
    },
    getEReceiptDataRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getEReceiptDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.eReceiptBlob = action.payload.blobUrl;
      state.eReceiptUrl = action.payload.actualUrl;
      state.profileInfo = action.payload.profileInfo;
      state.limitDetails = action.payload.limitDetails;
      state.error = "";
    },
    getEReceiptDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    },
    updateEReceiptDataRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    updateEReceiptDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.isUpdateSponsorSuccess = true
      state.error = "";
    },
    updateEReceiptDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    },
    getEreceiptTermsSuccess:(state: any, action: PayloadAction<any>)=>{
      state.isLoading = false;
      state.termsData = action.payload;
      state.error = "";
    },
    getEreceiptTermsFailure:(state: any, action: PayloadAction<any>)=>{
      state.isLoading = false;
      state.eReceiptBlob = "";
      state.eReceiptUrl = "";
      state.error = action.payload;
    }
  },
});

export const {
  getEReceiptDataRequest,
  getEReceiptDataSuccess,
  getEReceiptDataFailure,
  updateEReceiptDataRequest,
  updateEReceiptDataSuccess,
  updateEReceiptDataFailure,
  resetEreceiptDataRequest,
  getEreceiptTermsSuccess,
  getEreceiptTermsFailure
} = eReceiptSlice.actions;

export default eReceiptSlice.reducer;
