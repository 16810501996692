import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  ROPDataState } from "../models/models";

const initialState:ROPDataState = {
  ropDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const ropSlice = createSlice({
  name: "rop",
  initialState,
  reducers: {
    getROPDataRequest: (state) => {
      state.isLoading = true;
    },
    getROPDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.ropDetails = action.payload;
    },
    getROPDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const { getROPDataRequest, getROPDataSuccess, getROPDataFail } =
ropSlice.actions;
export default ropSlice.reducer;