import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChangePasswordDataState } from "../models/models";

const initialState: ChangePasswordDataState = {
  changePasswordDetails: [],
  error: {},
  isLoading: false,
  hasData: false,
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    changePasswordDataRequest: (
      state,
      action: PayloadAction<ChangePasswordDataState>
    ) => {
      state.isLoading = true;
      state.error = "";
    },
    changePasswordDataSuccess: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordDetails = action.payload;
    },

    changePasswordDataFailure: (
      state: any,
      action: PayloadAction<ChangePasswordDataState>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearOtpRefKeyResponse: (state) => {
      state.changePasswordDetails = [] ;
    },
  },
});

export const {
  changePasswordDataRequest,
  changePasswordDataSuccess,
  changePasswordDataFailure,
  clearOtpRefKeyResponse
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
