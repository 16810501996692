import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResetSubscription, SubscribeState, ResetSubscriptionError } from "../models/models";

const initialState: SubscribeState  = {
  subscribeDetails: {} as ResetSubscription,
  isLoading: false,
  error:{} as ResetSubscriptionError,
  hasData: false,
  subscribestatus: { status: false, message: "" },
};

const unSubscribeResetSlice = createSlice({
  name: "subscribeReset",
  initialState,
  reducers: {
    getSubscribeDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getSubscribeSuccess: (state, action: PayloadAction<any>) => {
      console.log("UnSubscribe playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.subscribeDetails = action.payload;
      state.subscribestatus.status = true
    },
    getSubscribeFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      state.subscribestatus.status = false
      if(state.error.code == "400" )
        {
          state.subscribestatus.message ="Invalid Email";
          state.subscribestatus.status = false;
        }
  
    },
    resetSubscribeData: (state) => {
      state.error={} as ResetSubscriptionError;
      state.isLoading = false;
      state.hasData=false;
      state.subscribeDetails = {} as ResetSubscription;
      state.subscribestatus = { status: false, message: "" };
    }, 
  },
});

export const {
    getSubscribeDataPending,
    getSubscribeFailure,
    getSubscribeSuccess,
    resetSubscribeData
} = unSubscribeResetSlice.actions;

export default unSubscribeResetSlice.reducer;
