import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdCarouselDataState } from "../models/models";

const initialState: AdCarouselDataState = {
  AdCarouselDetails: [],
  AdCardDetails: [],
  isLoadingCard: false,
  errorCard: "",
  isLoadingCarousel: false,
  errorCarousel: "",
};

const adCarouselSlice = createSlice({
  name: "adCarousel",
  initialState,
  reducers: {
    adCarouselDataRequest: (state) => {
      state.isLoadingCarousel = true;
    },
    getAdCarouselDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoadingCarousel = false;
      state.errorCarousel = "";
      state.AdCarouselDetails = action.payload;
    },
    getAdCarouselDataFailure: (state: any, action: PayloadAction<any>) => {
      state.errorCarousel = action.payload;
      state.isLoadingCarousel = false;
    },
    adCardDataRequest: (state) => {
      state.isLoadingCard = true;
    },
    getAdCardDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoadingCard = false;
      state.errorCard = "";
      state.AdCardDetails = action.payload;
    },
    getAdCardDataFailure: (state: any, action: PayloadAction<any>) => {
      state.errorCard = action.payload;
      state.isLoadingCard = false;
    },
  },
});

export const {
  adCarouselDataRequest,
  getAdCarouselDataSuccess,
  getAdCarouselDataFailure,
  adCardDataRequest,
  getAdCardDataSuccess,
  getAdCardDataFailure,
} = adCarouselSlice.actions;

export default adCarouselSlice.reducer;
