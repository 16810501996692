import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface FlightStatusData {
  flightRouteDetail: any | null;
  flightRouteInputs: any | null;
  flightRoute: any | null;
  isLoading: boolean;
  error: boolean;
}

const initialState: FlightStatusData = {
  flightRouteDetail: null,
  flightRouteInputs: null,
  flightRoute: null,
  isLoading: false,
  error: false,
};

const FlightStatusRouteSlice = createSlice({
  name: "flightStatus",
  initialState,
  reducers: {
    flightStatusByRouteRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = false;
    },
    flightStatusByRouteSuccess: (state: any, action: PayloadAction<any>) => {
      state.flightRouteDetail = action.payload;
      state.isLoading = false;
    },
    flightStatusByRouteFailure: (state: any, action: PayloadAction<any>) => {
      state.error = true;
      state.isLoading = false;
      state.flightRouteDetail = null;
    },
    flightStatusRouteInputSave: (state: any, action: PayloadAction<any>) => {
      state.flightRouteInputs = action.payload;
    },
    flightStatusRouteClear: (state: any) => {
      state.flightRouteInputs = null;
      state.flightRouteDetail= null;
      state.flightRoute= null;
      state.isLoading= false;
      state.error= false;
    },
  },
});

export const {
  flightStatusByRouteRequest,
  flightStatusByRouteSuccess,
  flightStatusByRouteFailure,
  flightStatusRouteInputSave,
  flightStatusRouteClear
} = FlightStatusRouteSlice.actions;

export default FlightStatusRouteSlice.reducer;
