import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  backTrackingAirportInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const backTrackingAirportSlice = createSlice({
  name: "backTrackingAirport",
  initialState,
  reducers: {
    backTrackingAirportRequest: (state) => {
      state.isLoading = true;
    },
    backTrackingAirportSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.backTrackingAirportInfo = action.payload;
    },
    backTrackingAirportFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    backTrackingAirportReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.backTrackingAirportInfo = {};
    },
  },
});

export const {
    backTrackingAirportRequest,
    backTrackingAirportSuccess,
    backTrackingAirportFailure,
    backTrackingAirportReset
} = backTrackingAirportSlice.actions;

export default backTrackingAirportSlice.reducer;
