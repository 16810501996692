import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SawasdeeDataState } from "../models/models";

const initialState: SawasdeeDataState = {
  SawasdeeMagDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const sawasdeeMagSlice = createSlice({
  name: "sawasdeeMagzine",
  initialState,
  reducers: {
    swMagDataRequest: (state) => {
      state.isLoading = true;
    },
    getSWDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.SawasdeeMagDetails = action.payload;
    },
    getSWDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {swMagDataRequest, getSWDataSuccess, getSWDataFailure} = sawasdeeMagSlice.actions;

export default sawasdeeMagSlice.reducer;