import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CRMOTPDataState } from "../models/models";

const initialState:CRMOTPDataState = {
    crmOTP:[],
    isLoading: false,
    error: "",
    hasData: false,
    codeStatus: "pending"
}

const crmOTPSlice =createSlice({
    name:"crmotpSection",
    initialState,
    reducers: {
        sendCRMOTPPending: (state) =>{
            state.isLoading = true;
            state.codeStatus = "pending";
        },
        sendCRMOTPSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = "";
            state.hasData = true;
            state.crmOTP = action.payload
            state.codeStatus = "success";
            sessionStorage.setItem(
                "customerID",
                action.payload.profileDetail.customerID
            );
            sessionStorage.setItem(
                "FirstName",
                action.payload?.profileDetail?.firstName
            );
            sessionStorage.setItem("isCRMLoggedIn", JSON.stringify(true));
            let fullName = "";
            fullName =
                action.payload.profileDetail?.salutation + " " +
                action.payload.profileDetail?.firstName +
                " " +
                action.payload?.profileDetail?.lastName;
            sessionStorage.setItem("FullName", fullName);
        },
        sendCRMOTPFailure: (state, action:PayloadAction) => {
            state.isLoading = false;
            state.error = action.payload;
            state.codeStatus = "failure";
        },
        resetCRMOTPData: (state) => {
            state.codeStatus = "pending";
            state.error = "";
            state.crmOTP = [];
            state.hasData = false;
        }
    }
})

export const { sendCRMOTPPending, sendCRMOTPSuccess, sendCRMOTPFailure, resetCRMOTPData} = crmOTPSlice.actions

export default crmOTPSlice.reducer;