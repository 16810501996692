import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  PromoCode, PromoCodeDataState,  } from "../models/models";

const initialState: PromoCodeDataState = {
  promoCodeDetails: {} as PromoCode,
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus:"pending",
  promoStatus:{status: false, message:""}
};

const promoSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
    getPromoCodePending: (state) => {
        state.isLoading = true;
        state.codeStatus="pending";
        state.error = "";
      },
      getPromoCodeSuccess: (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.error = "";
        state.hasData = true;
        state.promoCodeDetails = action.payload;
        console.log("promo payload", state.promoCodeDetails, action.payload);
        console.log("promo payload success",state.promoCodeDetails.success);
        if(state.promoCodeDetails.success)
          {
            console.log("eneter success");
            state.promoStatus.message ="";
            state.promoStatus.status = true;
          }
          else{

           console.log("enetred failure");
           console.log("message", state.promoCodeDetails.message);
            if(state.promoCodeDetails.message == "Promotion code error :Promotion code not found" )
              {
                console.log("enter not found")
                state.promoStatus.message ="Code is invalid";
                state.promoStatus.status = false;
              }else if(state.promoCodeDetails.message == "Promotion code error : Enter the correct origin city.,Enter the correct destination city.,Wrong Condition" )
                {
                  console.log("origin")
                  state.promoStatus.message ="Code is invalid";
                  state.promoStatus.status = false;
                }
                else if(state.promoCodeDetails.message == "Promotion code error: Promotion code expired"){
                  console.log("expired")
                  state.promoStatus.message ="Code expired";
                  state.promoStatus.status = false;
                }
          }
   
        console.log('state',state.promoCodeDetails)
        state.codeStatus="success";
      },
      getPromoCodeFail: (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.codeStatus="failure";
        state.error = action.payload;
      },
  },
});

export const {getPromoCodeSuccess, getPromoCodeFail, getPromoCodePending} = promoSlice.actions;

export default promoSlice.reducer;