import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GeoDataState } from "../../models/models";

const initialState:GeoDataState = {
  geolocationDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const geoSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    geolocationDataRequest: (state) => {
        state.isLoading = true;   
      },
      getGeolocationDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.geolocationDetails = action.payload;
      },
      getGeolocationDataFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      }
  },
});

export const {
  geolocationDataRequest,
    getGeolocationDataFailure,
    getGeolocationDataSuccess,
  } = geoSlice.actions;
  
  export default geoSlice.reducer;
  