import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { profileLifeStyleInfoCode, profileLifeStyleInfoState } from "../models/models";

const initialState: profileLifeStyleInfoState = {
  profileLifeStyleInfoDet: {} as profileLifeStyleInfoCode,
  isLoading: false,
  error:"",
  hasData: false,
  codeStatus: "pending",
  profileLifeStyleInfoStatus: { status: false, message: "" },
  setIsLifeStyleEditable: false,
};

const profileLifeStyleInfoSlice = createSlice({
  name: "profileLifeStyleInfo",
  initialState,
  reducers: {
    getProfileLifeStyleInfoPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
      state.profileLifeStyleInfoStatus.status = false;
    },
    getProfileLifeStyleInfoFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.profileLifeStyleInfoDet = action.payload;
      state.profileLifeStyleInfoStatus.status = false;
      // console.log("enter failure",action.payload);
      // if(state.profileContactInfoDet.code =="I120")
      //   {
      //     console.log("enter failure");
      //     state.profileContactInfoStatus.message ="Successfully Updated";
      //     state.profileContactInfoStatus.status = true;
      //     // sessionStorage.setItem('memberId',state.profileContactInfoDet.memberId);
      //   }
 
      // state.codeStatus = "success";
     
    },
    getProfileLifeStyleInfoSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      // state.profileContactInfoDet = action.payload.response.data;
      
      state.profileLifeStyleInfoStatus.message = "LifeStyle updated successfully";
      state.profileLifeStyleInfoStatus.status = true;
        
  
    },
    updateSignInState: (state: any, action: PayloadAction<any>) => {
      const { updatedState } = action.payload;
      state.isSignin = updatedState;
    },

    setIsLifeStyleEditable:(state: any, action: PayloadAction<any>) => {
      state.setIsLifeStyleEditable = action.payload;
    },
  },
});

export const {
  getProfileLifeStyleInfoPending,
  getProfileLifeStyleInfoFailure,
  getProfileLifeStyleInfoSuccess,
  updateSignInState,
  setIsLifeStyleEditable
} = profileLifeStyleInfoSlice.actions;

export default profileLifeStyleInfoSlice.reducer;
