import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SocialApp, SocialAppDataState } from "../models/models";

const initialState: SocialAppDataState = {
  social: {} as SocialApp,
  isLoading: false,
  error: "",
  hasData: false,
};

const socialSlice = createSlice({
  name: "socialMediaApp",
  initialState,
  reducers: {
    getSocialMediaData: (state) => {
      state.isLoading = true;
    },
    getSocialMediaDataSucess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.social = action.payload;
    },
    getSocialMediaDataFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSocialMediaData,
  getSocialMediaDataSucess,
  getSocialMediaDataFailure,
} = socialSlice.actions;

export default socialSlice.reducer;
