import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardReviewPnrInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
  selectedPageId: "",
};

const airAwardReviewPnrSlice = createSlice({
  name: "airAwardReviewPnr",
  initialState,
  reducers: {
    airAwardReviewPnrRequest: (state) => {
      state.isLoading = true;
    },
    airAwardReviewPnrSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardReviewPnrInfo = action.payload;
    },
    airAwardReviewPnrFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardReviewPnrReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardReviewPnrInfo = {};
    },
    savePageId: (state, action: PayloadAction<any>) => {
      state.selectedPageId = action.payload;
    },
  },
});

export const {
  airAwardReviewPnrRequest,
  airAwardReviewPnrSuccess,
  airAwardReviewPnrFailure,
  airAwardReviewPnrReset,
  savePageId,
} = airAwardReviewPnrSlice.actions;

export default airAwardReviewPnrSlice.reducer;
