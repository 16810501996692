import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tripsDetails: {},
  isLoading: false,
  hasData: false,
  error: ""
};
const fetchTripsSlice = createSlice({
  name: "tripsSlice",
  initialState,
  reducers: {
    tripsDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getTripsDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myTripsDetails = action.payload;
    },
    getTripsDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});
export const {
  tripsDataRequest,
  getTripsDataSuccess,
  getTripsDataFailure
} = fetchTripsSlice.actions;
export default fetchTripsSlice.reducer;