import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightInfoState } from "../models/models";

const initialState: FlightInfoState = {
  flightInfoDetails: {},
  minMilesObj: {},
  flightInfoResponse: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
  },
  isLoading: false,
  error: "",
  hasData: false,
  flightInfoArr: [],
  diaplayContinueButton: true,
  selectedFlightList: [],
  redemptionBookingData: [],
  redemptionBookingDates: [],
  selectedRuleSet: "",
  selectedFlightIndex: [],
};

const flightInfoSlice = createSlice({
  name: "flightInfo",
  initialState,
  reducers: {
    flightInfoRequest: (state) => {
      state.isLoading = true;
    },
    flightInfoSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.flightInfoDetails = action.payload;
      state.flightInfoResponse[action.payload.id] = action.payload;
      // state.minMilesObj[action.payload.id] = action.payload?.data?.flightList[0]?.classList[0]?.miles;
      state.minMilesObj[action.payload.id] = action.payload?.data?.lowestMiles;
      state.flightInfoArr = [
        ...state?.flightInfoArr,
        {
          ...action.payload,
          requestParam: {
            ...JSON.parse(action.payload.config.data),
            ...action.payload.moreInfo,
          },
          id: action.payload.id,
        },
      ].sort((x, y) => x.id - y.id);
    },
    flightInfoFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.flightInfoDetails = {};
      state.flightInfoResponse[action.payload.id] = action.payload;
      state.minMilesObj = {};
      state.flightInfoArr = [
        ...state?.flightInfoArr,
        {
          ...action.payload,
          requestParam: {
            ...JSON.parse(action.payload.config.data),
            ...action.payload.moreInfo,
          },
          id: action.payload.id,
        },
      ].sort((x, y) => x.id - y.id);
    },
    clearFlightInfoData: (state, action: PayloadAction<any>) => {
      state.flightInfoArr = [];
      state.flightInfoDetails = {};
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.minMilesObj = {};
    },
    clearFlightInfoDatawithId: (state, action: PayloadAction<any>) => {
      let filteredArr = state.flightInfoArr.filter(
        (x) => x.id !== action.payload.id
      );
      state.flightInfoArr = filteredArr;
    },
    disable_continue_btn: (state, action: PayloadAction<any>) => {
      state.diaplayContinueButton = action.payload;
    },
    selected_flightInfo_data: (state, action: PayloadAction<any>) => {
      state.selectedFlightList = action.payload;
    },
    clearSelectedFlightInfoData: (state) => {
      state.selectedFlightList = [];
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    saveRuleSetId: (state, action: PayloadAction<any>) => {
      state.selectedRuleSet = action.payload;
    },
    saveRedemptionBookingData: (state, action: PayloadAction<any>) => {
      state.redemptionBookingData = action.payload;
    },
    saveRedemptionBookingDates: (state, action: PayloadAction<any>) => {
      state.redemptionBookingDates = action.payload;
    },
    updateRedemptionBookingDates: (state, action: PayloadAction<any>) => {
      state.redemptionBookingDates = state.redemptionBookingDates?.map(
        (val, index) =>
          index.toString() === action.payload.id.toString()
            ? action.payload.updatedDate
            : val
      );
    },
    selected_flight_index: (state, action: PayloadAction<any>) => {
      state.selectedFlightIndex = action.payload;
    },
    clearSelectedFlightIndex: (state) => {
      state.selectedFlightIndex = [];
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
    },
    resetRedemeptionBookingData: (state: any) => {
      state.redemptionBookingData = [];
    },
    
  },
});

export const {
  flightInfoRequest,
  flightInfoSuccess,
  flightInfoFail,
  clearFlightInfoData,
  clearFlightInfoDatawithId,
  disable_continue_btn,
  selected_flightInfo_data,
  saveRedemptionBookingData,
  saveRedemptionBookingDates,
  saveRuleSetId,
  updateRedemptionBookingDates,
  selected_flight_index,
  clearSelectedFlightIndex,
  clearSelectedFlightInfoData,
  resetRedemeptionBookingData,
} = flightInfoSlice.actions;

export default flightInfoSlice.reducer;
