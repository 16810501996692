import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationDataState, SawasdeeDataState } from "../models/models";

const initialState: NotificationDataState = {
  NotificationDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationDataRequest: (state) => {
      state.isLoading = true;
    },
    getNotificationDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.NotificationDetails = action.payload;
    },
    getNotificationDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {notificationDataRequest, getNotificationDataSuccess, getNotificationDataFailure} = notificationSlice.actions;

export default notificationSlice.reducer;