import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NewAnnouncementDataState} from "../models/models";
import { NewsAnnouncementData } from "../models/models";

const initialState: NewAnnouncementDataState = {
  NewsAnnouncementDetails: {} as NewsAnnouncementData,
  isLoading:false,
  error: "",
  hasData: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    newsDataRequest: (state) => {
      state.isLoading = true;
    },
    newsDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.NewsAnnouncementDetails = action.payload;
    },
    newsDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {newsDataRequest, newsDataSuccess, newsDataFailure} = newsSlice.actions;

export default newsSlice.reducer;