import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  pnrDetails: [],
  isLoading: false,
  hasData: false,
  error: "",
};

const pnrDetailsSlice = createSlice({
  name: "pnrDetails",
  initialState,
  reducers: {
    getTripsPnrDetailsRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = false;
    },
    getTripsPnrDetailsSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = false;
      state.pnrDetails = action.payload;
    },
    getTripsPnrDetailsFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getTripsPnrDetailsRequest,
  getTripsPnrDetailsSuccess,
  getTripsPnrDetailsFailure,
  
} = pnrDetailsSlice.actions;

export default pnrDetailsSlice.reducer;
