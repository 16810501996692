import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PromotionCardDataState } from "../models/models";

const initialState: PromotionCardDataState = {
  promotionDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const promotionCardSlice = createSlice({
  name: "promotionCard",
  initialState,
  reducers: {
    promotionCardDataRequest: (state) => {
      state.isLoading = true;
    },
    getPromotionCardDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.promotionDetails = action.payload;
    },
    getPromotionCardDataFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  promotionCardDataRequest,
  getPromotionCardDataSuccess,
  getPromotionCardDataFailure,
} = promotionCardSlice.actions;

export default promotionCardSlice.reducer;
