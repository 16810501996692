import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { tripDetails } from "../models/models";

const initialState: any = {
  myTripsDetails: {} as tripDetails,
  pnrDetails: {},
  checkinDetails: {},
  eReceiptDetails: {},
  isLoading: false,
  hasData: false,
  error: "",
  addMyTripResponse: "",
  isAddTripSuccess: false,
  addTriperror: "",
  destination: "",
};

const fetchMyTripsSlice = createSlice({
  name: "myTrips",
  initialState,
  reducers: {
    myTripsDataRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getMyTripsDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myTripsDetails = action.payload as tripDetails;
    },
    getMyTripDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    myTripsPnrRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getPnrDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.pnrDetails = action.payload;
    },
    getPnrDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetTripDetails: (state: any) => {
      state.isLoading = false;
      state.myTripsDetails = {} as tripDetails;
      state.hasData = false;
      state.error = "";
    },
    postAddTripPending: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.isAddTripSuccess = false;
      state.addTriperror = "";
    },

    postAddTripSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.addTriperror = "";
      state.hasData = true;
      state.isAddTripSuccess = true;
      state.addMyTripResponse = action.payload as tripDetails;
    },
    postAddTripFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.isAddTripSuccess = false;
      state.error = action.payload.message;
      state.addTriperror = action.payload.response.data.message;
    },
    myTripsCheckinRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    postCheckInDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkinDetails = action.payload;
    },
    postCheckInDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetPnrDetails: (state: any) => {
      state.isLoading = false;
      state.pnrDetails = {};
      state.eReceiptDetails = {};
      state.hasData = false;
      state.error = "";
    },
    retrieveTripsRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = false;
    },
    retrieveTripsRequestSuccess: (state: any, action: PayloadAction<any>) => {
      const { payload, index } = action.payload;
      state.isLoading = false;
      state.error = false;
      state.myTripsDetails.pnrDetails[index].flightDetails = payload;
    },
    retrieveTripsRequestFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setEReceiptState: (state: any, action: PayloadAction<any>) => {
      state.eReceiptDetails = action.payload;
    },
    setMyTripDestination: (state: any, action: PayloadAction<any>) => {
      state.destination = action.payload;
    },
  },
});

export const {
  myTripsDataRequest,
  getMyTripsDataSuccess,
  getMyTripDataFailure,
  resetTripDetails,
  postAddTripPending,
  postAddTripSuccess,
  postAddTripFailure,
  myTripsPnrRequest,
  getPnrDataSuccess,
  getPnrDataFailure,
  myTripsCheckinRequest,
  postCheckInDataSuccess,
  postCheckInDataFailure,
  resetPnrDetails,
  retrieveTripsRequest,
  retrieveTripsRequestFailure,
  retrieveTripsRequestSuccess,
  setEReceiptState,
  setMyTripDestination,
} = fetchMyTripsSlice.actions;

export default fetchMyTripsSlice.reducer;
