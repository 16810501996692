import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NomineeDeletion, DeleteNomineeError } from "../models/models";

const initialState: any = {
  nomineeData: {},
  addNominee: {},
  removeNomineeDetails: {} as NomineeDeletion,
  addNomineeError: "",
  removeNomineeError: {} as DeleteNomineeError,
  isLoading: false,
  error: "",
  hasData: false,
  removeNomineeStatus: { status: false, message: "" },
  nomineeTermsData: {},
  nomineeTermsError: "",
  nomineeTabState: {},
  paidInsert: false,
  memberIcon:""
};

const fetchNomineeSlice = createSlice({
  name: "nominee",
  initialState,
  reducers: {
    fetchNomineeRequest: (state) => {
      state.isLoading = true;
    },
    getNomineeSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.nomineeData = action.payload;
    },
    getNomineeFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.nomineeData = {};
    },

    addNomineeRequest: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    addNomineeSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.addNomineeError = "";
      state.addNominee = action.payload;
    },
    addNomineeFailure: (state: any, action: PayloadAction<any>) => {
      state.addNomineeError = action.payload;
      state.isLoading = false;
      state.addNominee = {};
    },
    resetAddNomineeDetails: (state: any) => {
      state.addNominee = {};
      state.addNomineeError = "";
    },

    removeNomineeRequest: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    removeNomineeSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.removeNomineeError = "";
      state.hasData = true;
      state.removeNomineeDetails = action.payload;
      state.removeNomineeStatus.status = true;
    },
    removeNomineeFailure: (state: any, action: PayloadAction<any>) => {
      state.removeNomineeError = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.removeNomineeDetails = {};
      state.removeNomineeStatus.status = false;
    },
    resetRemoveNomineeDetails: (state) => {
      state.removeNomineeDetails = {} as NomineeDeletion;
      state.isLoading = false;
      state.removeNomineeError = {} as DeleteNomineeError;
      state.hasData = false;
      state.removeNomineeStatus = { status: false, message: "" };
    },
    getNomineeTermsRequest: (state: any) => {
      state.isLoading = true;
    },
    getNomineeTermsSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.nomineeTermsError = "";
      state.hasData = true;
      state.nomineeTermsData = action.payload;
    },
    getNomineeTermsFailure: (state: any, action: PayloadAction<any>) => {
      state.nomineeTermsError = action.payload;
      state.isLoading = false;
    },
    updateNomineeTabState: (state: any, action: PayloadAction<any>) => {
      state.nomineeTabState = action.payload;
    },
    setPaidInsertState:(state: any, action: PayloadAction<any>) => {
      state.paidInsert = action.payload;
    },
    setMemberIconState:(state: any, action: PayloadAction<any>) => {
      state.memberIcon = action.payload;
    },
  },
});

export const {
  fetchNomineeRequest,
  getNomineeSuccess,
  getNomineeFailure,
  addNomineeRequest,
  addNomineeSuccess,
  addNomineeFailure,
  resetAddNomineeDetails,
  removeNomineeRequest,
  removeNomineeSuccess,
  removeNomineeFailure,
  resetRemoveNomineeDetails,
  getNomineeTermsRequest,
  getNomineeTermsSuccess,
  getNomineeTermsFailure,
  updateNomineeTabState,
  setPaidInsertState,
  setMemberIconState
} = fetchNomineeSlice.actions;

export default fetchNomineeSlice.reducer;
