import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  OTPDataState } from "../models/models";

const initialState:OTPDataState = {
  otpDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus:"pending",
};

const otpSlice = createSlice({
  name: "otpSection",
  initialState,
  reducers: {
    sendOTPPending: (state) => {
      state.isLoading = true;
      state.codeStatus="pending";
    },
    sendOTPSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.otpDetails = action.payload;
      state.codeStatus="success";
      sessionStorage.setItem("isROPLoggedIn", JSON.stringify(true));
      sessionStorage.setItem("privilegeCard",action.payload.data.privilegeCard);
    },
    sendOTPFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus="failure";
    }, 
    resetOTPData: (state) => {
      state.codeStatus="pending";
      state.error="";
      state.otpDetails=[];
      state.hasData=false;
    }, 
  },
});

export const { sendOTPPending, sendOTPSuccess, sendOTPFail, resetOTPData } =
otpSlice.actions;

export default otpSlice.reducer;