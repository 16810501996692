import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IdCarouselDataState } from "../models/models";

const initialState: IdCarouselDataState = {
    IdCardDetails: [],
    countryList: [],
    isLoading: false,
    error: "",
    hasData: false,
};

const languageSlice = createSlice({
    name: "languages",
    initialState,
    reducers: {
        languageDataRequest: (state) => {
            state.isLoading = true;
        },
        getlanguageDataSuccess: (state: any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = "";
            state.countryList = action.payload;
        },
        getlanguageDataFailure: (state: any, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    languageDataRequest,
    getlanguageDataSuccess,
    getlanguageDataFailure,
} = languageSlice.actions;

export default languageSlice.reducer;