import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  CrmResendOTPData, CrmResendOTPDataState } from "../models/models";

const initialState: CrmResendOTPDataState= {
  crmResendOTPDetails: {},
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus:"pending"
};

const crmResendOTPSlice = createSlice({
  name: "crmResendSection",
  initialState,
  reducers: {
   crmResendOTPPending: (state, action:PayloadAction<CrmResendOTPData>) => {
      state.isLoading = true;
      state.codeStatus="pending";
    },
    crmResendOTPSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmResendOTPDetails = action.payload;
      state.codeStatus="success";
    },
    crmResendOTPFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus="failure";
      state.hasData = false;
    }, 
    crmResendOTPClear: (state) => {
      console.log("test crm otp clear", state)
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.crmResendOTPDetails = {};
      state.codeStatus="pending";
    },
  },
});

export const { crmResendOTPPending, crmResendOTPSuccess, crmResendOTPFail, crmResendOTPClear } =
crmResendOTPSlice.actions;

export default crmResendOTPSlice.reducer;