import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AirAwardConfirmationState } from "../models/models";

const initialState: AirAwardConfirmationState = {
  data: {},
  isLoading: false,
  error: "",
  hasData: false,
};

const airAwardConfirmationSlice = createSlice({
  name: "airAwardConfirmationData",
  initialState,
  reducers: {
    airAwardConfirmationRequest: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    airAwardConfirmationSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.data = action.payload;
    },
    airAwardConfirmationFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardConfirmationReset: (state) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.data = {};
    },
  },
});

export const { airAwardConfirmationRequest, airAwardConfirmationSuccess, airAwardConfirmationFail, airAwardConfirmationReset } =
  airAwardConfirmationSlice.actions;

export default airAwardConfirmationSlice.reducer;
