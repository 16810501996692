import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdvertisementDataState } from "../models/models";

const initialState: AdvertisementDataState = {
  AdvertisementDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    advertisementDataRequest: (state) => {
      state.isLoading = true;
    },
    getAdvertisementDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.AdvertisementDetails = action.payload;
    },
    getAdvertisementDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  advertisementDataRequest,
  getAdvertisementDataSuccess,
  getAdvertisementDataFailure,
} = advertisementSlice.actions;

export default advertisementSlice.reducer;
