import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ResetCRM, ResetCRMState, ResetError } from "../models/models";

const initialState: ResetCRMState  = {
  resetCRMDetails: {} as ResetCRM,
  isLoading: false,
  error:{} as ResetError,
  hasData: false,
  codeStatus: "pending",
  crmstatus: { status: false, message: "" },
};

const crmResetSlice = createSlice({
  name: "crmReset",
  initialState,
  reducers: {
    getCRMDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    getCRMSuccess: (state, action: PayloadAction<any>) => {
      console.log("Action playload", action.payload);
      state.isLoading = false;
      state.hasData = true;
      state.resetCRMDetails = action.payload;
      state.codeStatus = "success";
     
    },
    getCRMFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.codeStatus = "failure";
      state.error = action.payload.response.data;
      if(state.error.Code == "400" )
        {
          state.crmstatus.message ="Invalid Email";
          state.crmstatus.status = false;
        }
  
    },
    resetCRMData: (state) => {
      state.codeStatus="pending";
      state.error={} as ResetError;
      state.isLoading = false;
      state.hasData=false;
      state.resetCRMDetails = {} as ResetCRM;
    }, 
  },
});

export const {
  getCRMDataPending,
  getCRMFailure,
  getCRMSuccess,
  resetCRMData
} = crmResetSlice.actions;

export default crmResetSlice.reducer;
