import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  myprofilMilesData: {},
  isLoading: false,
  error: "",
  hasData: false,
};

const fetchMainProfileMilesSlice = createSlice({
  name: "MyProfileMilesDeails",
  initialState,
  reducers: {
    fetchMyProfileMilesRequest: (state) => {
        state.isLoading = true;
      },
      getMyProfileMilesSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myprofileMilesData = action.payload;
      },
      getMyProfileMilesFailure: (state: any, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
        state.hasData = false;
        state.myprofileMilesData = {}
      }
  },
});

export const {
    fetchMyProfileMilesRequest,
    getMyProfileMilesSuccess,
    getMyProfileMilesFailure,
  } = fetchMainProfileMilesSlice.actions;
  
  export default fetchMainProfileMilesSlice.reducer;
  