import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AirportListRedemptionData,
  RedemptionFieldData,
} from "../models/models";

export interface RedemptionData {
  airportDataRedemption: null | AirportListRedemptionData;
  fieldData: RedemptionFieldData | null;
  isLoading: Boolean;
  error: Boolean;
  multiCityFieldArr: Array<any>;
}

const initialState: RedemptionData = {
  airportDataRedemption: null,
  fieldData: null,
  isLoading: false,
  error: false,
  multiCityFieldArr: []
};

const getDateFormat = (date: any, type?: string) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (type) {
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

const redemptionSlice = createSlice({
  name: "Redemption",
  initialState,
  reducers: {
    airportRedemptionDataRequest: (state: any) => {
      state.isLoading = true;
      state.error = null;
    },
    getAirportRedemptionDataSuccess: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.airportDataRedemption = action.payload;
      state.isLoading = false;
    },
    getAirportRedemptionDataFailure: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateRedemptionFieldData: (state: any, action: PayloadAction<any>) => {
      const { fieldLabel, newValue } = action.payload;
      if (state.fieldData === null) {
        state.fieldData = {
          from: "",
          to: "",
          departReturn: "",
          depart: "",
          passengers: {},
        };
      }
      if (fieldLabel === "from" || fieldLabel === "to") {
        state.fieldData[fieldLabel] = newValue[0];
      } else if (fieldLabel === "departReturn") {
        const formattedDates = newValue.map((date: any) => {
          if (date === null) return null;
          return getDateFormat(date);
        });
        state.fieldData[fieldLabel] = formattedDates;
      } else if (fieldLabel === "depart") {
        const formattedDate = getDateFormat(newValue[0]);
        state.fieldData[fieldLabel] = formattedDate;
      } else if (fieldLabel === "passengers") {
        state.fieldData[fieldLabel] = newValue.target.value;
      } else {
        state.fieldData[fieldLabel] = newValue.target.value;
      }
    },
    saveRedemptionMultiCityFieldData: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.multiCityFieldArr = action.payload;
    },
    resetRedeemFieldData: (state: any) => {
      state.fieldData = {
        from: "",
        to: "",
        departReturn: "",
        depart: "",
        passengers: "",
      };
    },
    resetRedeemBookFlightLabel: (state: any, action: PayloadAction<any>) => {
      const { fieldLabel } = action.payload;
      state.fieldData[fieldLabel] = "";
    },
  },
});

export const {
  airportRedemptionDataRequest,
  getAirportRedemptionDataFailure,
  getAirportRedemptionDataSuccess,
  updateRedemptionFieldData,
  resetRedeemFieldData,
  resetRedeemBookFlightLabel,
  saveRedemptionMultiCityFieldData
} = redemptionSlice.actions;

export default redemptionSlice.reducer;
