import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  baggage: {},
  isLoading: false,
  error: "",
  hasData: false,
};

const baggageAllowanceSlice = createSlice({
  name: "baggageAllowance",
  initialState,
  reducers: {
    getBaggageAllowanceRequest: (state) => {
      state.isLoading = true;
    },
    getBaggageAllowanceSucess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.baggage = action.payload;
    },
    getBaggageAllowanceFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getBaggageAllowanceRequest,
  getBaggageAllowanceSucess,
  getBaggageAllowanceFailure,
} = baggageAllowanceSlice.actions;

export default baggageAllowanceSlice.reducer;
