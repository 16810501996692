import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardTstInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardTstSlice = createSlice({
  name: "airAwardTst",
  initialState,
  reducers: {
    airAwardTstRequest: (state) => {
      state.isLoading = true;
    },
    airAwardTstSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardTstInfo = action.payload;
    },
    airAwardTstFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardTstReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardTstInfo = {};
    },
  },
});

export const {
    airAwardTstRequest,
    airAwardTstSuccess,
    airAwardTstFailure,
    airAwardTstReset
} = airAwardTstSlice.actions;

export default airAwardTstSlice.reducer;
