import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  HomeDataState } from "../models/models";

const initialState:HomeDataState = {
  homepageDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const homeSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    getHomePageDataPending: (state) => {
      state.isLoading = true;
    },
    getHomePageDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.homepageDetails = action.payload;
    },
    getHomePageDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }, 
  },
});

export const { getHomePageDataPending, getHomePageDataSuccess, getHomePageDataFail } =
homeSlice.actions;

export default homeSlice.reducer;