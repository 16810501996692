import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdditionalServiceData } from "../models/models";

const initialState: AdditionalServiceData = {
  services: [],
  isLoading: false,
  hasData: false,
  error: "",
};

const AdditionalServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    additionalServiceDataRequest: (state) => {
      state.isLoading = true;
    },
    getAdditionalServiceDataSuccess: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      (state.error = ""), (state.service = action.payload);
    },
    getAdditionalServiceDataFailure: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  additionalServiceDataRequest,
  getAdditionalServiceDataSuccess,
  getAdditionalServiceDataFailure,
} = AdditionalServiceSlice.actions;

export default AdditionalServiceSlice.reducer;
