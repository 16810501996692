import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarPricingData } from "../models/models";

const initialState: CalendarPricingData = {
    prices:[],
    isLoading: false,
    error: "",
    hasData: false,
    codeStatus: "pending"
}

const calendarPricingSlice = createSlice({
    name:"calendarFareSection",
    initialState,
    reducers:{
        sendCalendarFareData: (state, action: PayloadAction<any>) => {
            state.isLoading = true;
            state.codeStatus = "pending";
        },
        sendCalendarFareSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = "";
            state.hasData = true;
            state.codeStatus = "success";
            state.prices = action?.payload?.prices;
        },
        sendCalendarFareFailure: (state, action:PayloadAction<any>) => {
            state.isLoading = true;
            state.codeStatus = "failure";
            state.error = action.payload;
        },
        resetCalendarFareData:(state) => {
            state.codeStatus = "pending";
            state.error = "";
            state.hasData = false;
        }
    }
})

export const { sendCalendarFareData, sendCalendarFareSuccess, sendCalendarFareFailure, resetCalendarFareData} = calendarPricingSlice.actions;

export default calendarPricingSlice.reducer;