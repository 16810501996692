import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardBookingInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardBookingSlice = createSlice({
  name: "airAwardBooking",
  initialState,
  reducers: {
    airAwardBookingRequest: (state) => {
      state.isLoading = true;
    },
    airAwardBookingSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardBookingInfo = action.payload;
    },
    airAwardBookingFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardBookingReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardBookingInfo = {};
    },
  },
});

export const {
  airAwardBookingRequest,
  airAwardBookingSuccess,
  airAwardBookingFailure,
  airAwardBookingReset,
} = airAwardBookingSlice.actions;

export default airAwardBookingSlice.reducer;
