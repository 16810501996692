import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardAirportInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardAirportSlice = createSlice({
  name: "airAwardAirport",
  initialState,
  reducers: {
    airAwardAirportRequest: (state) => {
      state.isLoading = true;
    },
    airAwardAirportSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardAirportInfo = action.payload;
    },
    airAwardAirportFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardAirportReset: (state: any) => {
        state.isLoading = false;
        state.error = "";
        state.hasData = false;
        state.airAwardAirportInfo = {};
      },
  },
});

export const {
    airAwardAirportRequest,
    airAwardAirportSuccess,
    airAwardAirportFailure,
    airAwardAirportReset,
} = airAwardAirportSlice.actions;

export default airAwardAirportSlice.reducer;
