import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReSubscription, ResubscribeState, ResubscriptionError } from "../models/models";

const initialState: ResubscribeState  = {
  resubscribeDetails: {} as ReSubscription,
  isLoading: false,
  error:{} as ResubscriptionError,
  hasData: false,
  resubscribestatus: { status: false, message: "" },
};

const reSubscribeResetSlice = createSlice({
  name: "resubscribeReset",
  initialState,
  reducers: {
    getResubscribeDataPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    getReSubscribeSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.resubscribeDetails = action.payload;
    },
    getReSubscribeFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.response.data;
      if(state.error.code == "400" )
        {
          state.resubscribestatus.message ="Invalid Email";
          state.resubscribestatus.status = false;
        }
  
    },
    resetReSubscribeData: (state) => {
      state.error={} as ResubscriptionError;
      state.isLoading = false;
      state.hasData=false;
      state.resubscribeDetails = {} as ReSubscription;
    }, 
  },
});

export const {
  getResubscribeDataPending,
  getReSubscribeSuccess,
  getReSubscribeFailure,
  resetReSubscribeData
} = reSubscribeResetSlice.actions;

export default reSubscribeResetSlice.reducer;
