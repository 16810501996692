import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState, SelectedRoutes } from "../models/models";

const initialState: FlightListsDataState = {
  selectedRoutes: {} as SelectedRoutes,
  isLoading: false,
  hasData: false,
  error: "",
};

const flightListsSlice = createSlice({
  name: "selectedRoutes",
  initialState,
  reducers: {
    flightListDataRequest: (state) => {
      state.isLoading = true;
    },
    getFlightListDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.selectedRoutes = action.payload;
    },
    getFlightListDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = action.payload;
    },
  },
});

export const {
  flightListDataRequest,
  getFlightListDataSuccess,
  getFlightListDataFailure,
} = flightListsSlice.actions;

export default flightListsSlice.reducer;
