import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ChangePasswordResendOTPDataState,
  ChangePasswordResendOTPData,
} from "../models/models";

const initialState: ChangePasswordResendOTPDataState = {
  changePasswordResendOTPDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
};

const changePasswordOTPResendSlice = createSlice({
  name: "resendSection",
  initialState,
  reducers: {
    changePasswordResendOTPPending: (
      state,
      action: PayloadAction<ChangePasswordResendOTPData>
    ) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    changePasswordResendOTPSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordResendOTPDetails = action.payload;
      state.codeStatus = "success";
      sessionStorage.setItem("refNumber", action.payload.data.otpRefKey);
    },
    changePasswordResendOTPFail: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
      state.hasData = false;
    },
    changePasswordResendOTPClear: (state) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.changePasswordResendOTPDetails = [];
      state.codeStatus = "pending";
    },
  },
});

export const {
  changePasswordResendOTPPending,
  changePasswordResendOTPSuccess,
  changePasswordResendOTPFail,
  changePasswordResendOTPClear,
} = changePasswordOTPResendSlice.actions;

export default changePasswordOTPResendSlice.reducer;
