import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardReviewPnrCancelInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardReviewPnrCancelSlice = createSlice({
  name: "airAwardReviewPnrCancel",
  initialState,
  reducers: {
    airAwardReviewPnrCancelRequest: (state) => {
      state.isLoading = true;
    },
    airAwardReviewPnrCancelSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardReviewPnrCancelInfo = action.payload;
    },
    airAwardReviewPnrCancelFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardReviewPnrCancelReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardReviewPnrCancelInfo = {};
    },
  },
});

export const {
    airAwardReviewPnrCancelRequest,
    airAwardReviewPnrCancelSuccess,
    airAwardReviewPnrCancelFailure,
    airAwardReviewPnrCancelReset
} = airAwardReviewPnrCancelSlice.actions;

export default airAwardReviewPnrCancelSlice.reducer;
