import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  SecondaryCarouselDataState } from "../models/models";

const initialState:SecondaryCarouselDataState = {
  SecondaryCarouselDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const secondaryCarouselSlice = createSlice({
  name: "secondaryCarousel",
  initialState,
  reducers: {
    getHomeSecondaryCarouselDataRequest: (state) => {
      console.log('state', state)
      state.isLoading = true;
    },
    getHomeSecondaryCarouselDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.SecondaryCarouselDetails = action.payload;
    },
    getHomeSecondaryCarouselDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }, 
  },
});

export const { getHomeSecondaryCarouselDataRequest, getHomeSecondaryCarouselDataSuccess, getHomeSecondaryCarouselDataFail } =
secondaryCarouselSlice.actions;

export default secondaryCarouselSlice.reducer;