import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IdCarouselDataState } from "../models/models";

const initialState: IdCarouselDataState = {
  IdCardDetails: [],
  countryList:[],
  isLoading: false,
  error: "",
  hasData: false,
};

const idCarouselSlice = createSlice({
  name: "idCarousel",
  initialState,
  reducers: {
    idCardDataRequest: (state) => {
      state.isLoading = true;
    },
    getIdCardDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.IdCardDetails = action.payload;
    },
    getIdCardDataFailure: (state: any, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  idCardDataRequest,
  getIdCardDataSuccess,
  getIdCardDataFailure,
} = idCarouselSlice.actions;

export default idCarouselSlice.reducer;