import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalSearchDataState, GlobalSearchData } from "../models/models";

const initialState: GlobalSearchDataState = {
  globalSearchDetails: {} as GlobalSearchData,
  isLoading: true,
  error: "",
  hasData: false,
};

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    getGlobalSearchDataSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.globalSearchDetails = action.payload;
    },
    getGlobalSearchDataFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getGlobalSearchDataSuccess, getGlobalSearchDataFailure } =
  globalSearchSlice.actions;

export default globalSearchSlice.reducer;
