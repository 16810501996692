import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  TertiaryCarouselDataState } from "../models/models";

const initialState:TertiaryCarouselDataState = {
  TertiaryCarouselDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const tertiaryCarouselSlice = createSlice({
  name: "tertiaryCarousel",
  initialState,
  reducers: {
    getHomeTertiaryCarouselDataRequest: (state) => {
      state.isLoading = true;
    },
    getHomeTertiaryCarouselDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.TertiaryCarouselDetails = action.payload;
    },
    getHomeTertiaryCarouselDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }, 
  },
});

export const { getHomeTertiaryCarouselDataRequest, getHomeTertiaryCarouselDataSuccess, getHomeTertiaryCarouselDataFail } =
tertiaryCarouselSlice.actions;

export default tertiaryCarouselSlice.reducer;