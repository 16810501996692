import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ManageBookingDataState, tripDetails } from "../models/models";

const initialState: ManageBookingDataState = {
  upcomingTrips: {} as tripDetails,
  memberId: "",
  pnrDetails: [],
  isLoading: false,
  hasData: false,
  error: "",
};

const upcomingTripSlice = createSlice({
  name: "upcomingTrips",
  initialState,
  reducers: {
    tripDataRequest: (state) => {
      state.isLoading = true;
    },
    getTripDataSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.upcomingTrips = action.payload as tripDetails;
    },
    getTripDataFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateTripsPnrDetailsRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = false;
    },
    updateTripsPnrDetailsSuccess: (state: any, action: PayloadAction<any>) => {
      const {payload, index}=action.payload
      
      state.isLoading = false;
      state.error = false;
      state.upcomingTrips.pnrDetails[index].flightDetails = payload;
    },
    updateTripsPnrDetailsFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { tripDataRequest, getTripDataSuccess, getTripDataFailure,updateTripsPnrDetailsRequest,updateTripsPnrDetailsFailure,updateTripsPnrDetailsSuccess } =
  upcomingTripSlice.actions;

export default upcomingTripSlice.reducer;
