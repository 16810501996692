import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {  PromotionDataState, PromotionDataPayload } from "../models/models";

const initialState:PromotionDataState = {
  promotionDetails: [],
  isLoading: false,
  error: "",
  hasData: false,
};

const primaryCarouselSlice = createSlice({
  name: "primaryCarousel",
  initialState,
  reducers: {
    // getHomePrimaryCarouselDataRequest: (state) => {
    //   state.isLoading = true;
    // },
    // getHomePrimaryCarouselDataSuccess: (state, action:PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.error = "";
    //   state.hasData = true;
    //   state.CarouselDetails = action.payload;
    // },
    // getHomePrimaryCarouselDataFail: (state, action:PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // }, 
    getPromotionDataRequest: (state, action: PayloadAction<PromotionDataPayload>) => {
      state.isLoading = true;
    },
    getPromotionDataSuccess: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.promotionDetails = action.payload;
    },
    getPromotionDataFail: (state, action:PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

// export const { getHomePrimaryCarouselDataRequest, getHomePrimaryCarouselDataSuccess, getHomePrimaryCarouselDataFail } =
// primaryCarouselSlice.actions;
export const { getPromotionDataRequest, getPromotionDataSuccess, getPromotionDataFail } =
primaryCarouselSlice.actions;
export default primaryCarouselSlice.reducer;