import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isLoading: false,
  memberStatusTermsData: {},
  memberStatusTermsError: "",
  memberTierData:{},
  getDataSuccess : false,
  memberTierError:{},

};

const fetchMemberStatusCalculatorSlice = createSlice({
  name: "memberStatusCalculator",
  initialState,
  reducers: {
    getMemberStatusTermsSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.memberStatusTermsError = "";
      state.memberStatusTermsData = action.payload;
    },
    getMemberStatusTermsFailure: (state: any, action: PayloadAction<any>) => {
      state.memberStatusTermsError = action.payload;
      state.isLoading = false;
    },
    getMemberTierCalclulatorPending: (state, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.getDataSuccess = false;
    },
    getMemberTierCalclulatorFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.getDataSuccess = false;
	  state.memberTierError = action.payload;
    },
    getMemberTierCalclulatorSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.memberTierData = action.payload;
      state.getDataSuccess = true;
      
    },
  },
});

export const { getMemberStatusTermsSuccess, getMemberStatusTermsFailure,
  getMemberTierCalclulatorPending,
  getMemberTierCalclulatorSuccess,
  getMemberTierCalclulatorFailure,


 } =
  fetchMemberStatusCalculatorSlice.actions;

export default fetchMemberStatusCalculatorSlice.reducer;
