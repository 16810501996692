import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangePasswordOTPDataState } from "../models/models";

const initialState: ChangePasswordOTPDataState = {
  changePasswordOTP: [],
  isLoading: false,
  error: "",
  hasData: false,
  codeStatus: "pending",
  changePasswordStatus: { status: false, message: "" },
};

const changePasswordOTPSlice = createSlice({
  name: "changePasswordotpSection",
  initialState,
  reducers: {
    sendChangePasswordOTPPending: (state) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    sendChangePasswordOTPSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.changePasswordOTP = action.payload;
      state.codeStatus = "success";
      state.changePasswordStatus.message = "UPDATE has been successfully completed.";
      state.changePasswordStatus.status = true;
      sessionStorage.setItem("isROPLoggedIn", JSON.stringify(true));
    },
    sendChangePasswordOTPFailure: (state, action: PayloadAction) => {
      state.isLoading = false;
      state.error = action.payload;
      state.codeStatus = "failure";
    },
    resetChangePasswordOTPData: (state) => {
      state.codeStatus = "pending";
      state.error = "";
      state.changePasswordOTP = [];
      state.hasData = false;
      state.changePasswordStatus.message = "";
      state.changePasswordStatus.status = false;
    },
  },
});

export const {
  sendChangePasswordOTPPending,
  sendChangePasswordOTPSuccess,
  sendChangePasswordOTPFailure,
  resetChangePasswordOTPData,
} = changePasswordOTPSlice.actions;

export default changePasswordOTPSlice.reducer;
