import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlightListsDataState } from "../models/models";

const initialState: any = {
  airAwardPriceInfo: {} as any,
  isLoading: false,
  hasData: false,
  error: "",
};

const airAwardPriceSlice = createSlice({
  name: "airAwardPrice",
  initialState,
  reducers: {
    airAwardPriceRequest: (state) => {
      state.isLoading = true;
    },
    airAwardPriceSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.airAwardPriceInfo = action.payload;
    },
    airAwardPriceFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    airAwardPriceReset: (state: any) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = false;
      state.airAwardPriceInfo = {};
    },
  },
});

export const {
    airAwardPriceRequest,
    airAwardPriceSuccess,
    airAwardPriceFailure,
    airAwardPriceReset
} = airAwardPriceSlice.actions;

export default airAwardPriceSlice.reducer;
